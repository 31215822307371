import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';
import KENTICO_TYPES from 'common/consts/kentico-types';
import { FieldModels } from 'kentico-cloud-delivery/_es2015';
import AssetModel = FieldModels.AssetModel;

export default class ScrollSliderSectionSlideModel extends ContentItemWithResolver {
	static readonly type = KENTICO_TYPES.SCROLL_SLIDER_SECTION_SLIDE;

	get asset(): AssetModel {
		return get(this, '_asset.assets[0]', null);
	}

	get text(): string {
		return get(this, '_text.value', '');
	}
}
