import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';
import KENTICO_TYPES from 'common/consts/kentico-types';
import InnerLinkModel from 'models/InnerLinkModel';
import OuterLinkModel from 'models/OuterLinkModel';

export default class FooterModel extends ContentItemWithResolver {
	static readonly type = KENTICO_TYPES.FOOTER;

	get footerText(): string {
		return get(this, '_footer_text.value', '');
	}

	get findUsText(): string {
		return get(this, '_find_us_text.value', '');
	}

	get links(): InnerLinkModel | OuterLinkModel {
		return get(this, '_links', []);
	}

	get facebookLink(): OuterLinkModel {
		return get(this, '_facebook_link[0]', null);
	}

	get linkedinLink(): OuterLinkModel {
		return get(this, '_linkedin_link[0]', null);
	}
}
