import * as React from 'react';
import { Helmet } from 'react-helmet';
import SEOModel from 'models/SEOModel';
import GlobalMetaTagsModel from 'models/GlobalMetaTagsModel';

export interface SEOProps {
	data?: SEOModel;
	globalMetaTags?: GlobalMetaTagsModel;
}

const SEO: React.FC<SEOProps> = ({ data, globalMetaTags }) => {
	const {
		title = '',
		description = '',
		ogImage = '',
		ogType = '',
		metaKeywords = '',
	} = data || {};

	const renderGlobalMetaTags = () =>
		globalMetaTags ?
			globalMetaTags.map(({ id, name, content }) =>
				<meta key={id} name={name} content={content} />)
			:
			null;

	return (
		<Helmet defaultTitle='Holo4Labs' titleTemplate='%s'>
			{title && (
				<title>
					{title}
				</title>)}
			{description && <meta name='description' content={description} />}
			{title && <meta property='og:title' content={title} />}
			{ogType && <meta property='og:type' content={ogType} />}
			{ogImage && <meta property='og:image' content={ogImage} />}
			{metaKeywords && <meta property='keywords' content={metaKeywords} />}
			{description && (
				<meta property='og:description' content={description} />
			)}
			<meta name='twitter:card' content='summary' />
			{renderGlobalMetaTags()}
		</Helmet>
	);
};

export default SEO;
