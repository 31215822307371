import * as React from 'react';
import Layout from 'components/Layout/Layout';
import HeroSectionModel from 'models/HeroSectionModel';
import HorizontalLine from 'components/HorizontalLine/HorizontalLine';
import BlackNoiseBackground from 'components/BlackNoiseBackground/BlackNoiseBackground';
import Img from 'components/Img/Img';
import styles from './HeroSection.module';

export interface HeroSectionProps {
	data: HeroSectionModel;
}

const HeroSection: React.FC<HeroSectionProps> = ({ data }) => {
	const {
		headerTopLine,
		headerBottomLine,
		description,
		image,
		smallText,
	} = data;

	return (
		<section className={styles.Background}>
			<BlackNoiseBackground />
			<Layout
				className={styles.Layout}
			>
				<h1 className={styles.Header}>
					{headerTopLine}
					{' '}
					{headerBottomLine && (
						<React.Fragment>
							<br className={styles.Br} />{headerBottomLine}
						</React.Fragment>
					)}
				</h1>
				<p className={styles.Description}>
					{description}
				</p>
				<Img 
					className={styles.Image}
					src={image.url}
					alt={image.description}
				/>
				<HorizontalLine
					className={styles.HorizontalLine}
				/>
				<p className={styles.SmallText}>{smallText}</p>
			</Layout>
		</section>
	);
};

export default HeroSection;
