import * as React from 'react';
import ColumnModel from 'models/ColumnModel';
import Img from 'components/Img/Img';
import styles from './Column.module';

export interface ColumnProps {
	data: ColumnModel;
	className?: string;
}

const Column: React.FC<ColumnProps> = ({ data, className }) => {
	const {
		headerTop,
		headerBottom,
		image,
		text,
	} = data;

	return (
		<div className={`${className} ${styles.Container}`}>
			<header>
				<h3 className={styles.HeaderTop}>
					{headerTop}
				</h3>
				<p className={styles.HeaderBottom}>
					{headerBottom}
				</p>
			</header>
			<p
				className={styles.Text}
			>
				{text}
			</p>
			{image && (
				<Img
					className={styles.Image}
					src={image.url}
					alt={image.description}
				/>
			)}
		</div>
	);
};

Column.defaultProps = {
	className: '',
};

export default Column;
