/* eslint-disable react/prop-types */
import * as React from 'react';
import root from 'window-or-global';
import styles from './ParallaxVideo.module.scss';

export interface ParallaxVideoProps {
	src: string;
	className?: string;
	videoClassName?: string;
	videoOptions?: any;
}

class ParallaxVideo extends React.Component<ParallaxVideoProps> {

	static defaultProps = {
		src: '',
		className: '',
		videoClassName: '',
		videoOptions: {},
	};
	backgroundRef = null;
	videoRef = null;

	constructor(props) {
		super(props);
		this.backgroundRef = React.createRef();
		this.videoRef = React.createRef();
	}

	componentDidMount() {
		this.onScroll();
		root.addEventListener('scroll', this.onScroll);
		root.addEventListener('resize', this.onScroll);
	}

	componentWillUnmount() {
		root.removeEventListener('scroll', this.onScroll);
		root.removeEventListener('resize', this.onScroll);
	}

	onScroll = () => {
		const parallaxRect = this.backgroundRef.current.getBoundingClientRect();
		const vpHeight = root.innerHeight || root.clientHeight;
		// do parallax only when container is in viewport:
		if (parallaxRect.top > -parallaxRect.height && !(parallaxRect.top > vpHeight)) {
			this.parallax(this.backgroundRef, this.videoRef, 300);
		}
	}

	parallax(containerRef, objectRef, speed) {
		root.requestAnimationFrame(() => {
			const objectElement = objectRef.current;
			const containerElement = containerRef.current;

			if (!objectElement || !containerElement) {
				return;
			}

			const containerParams = containerElement.getBoundingClientRect();
			const objectHeight = objectElement.getBoundingClientRect().height;
			const viewportHeight = root.innerHeight || root.clientHeight;

			const y = Math.abs(containerParams.height / viewportHeight - 1);
			const translationValue = speed * y;

			const containerHeight = (
				containerParams.height < viewportHeight
					? objectHeight - translationValue
					: objectHeight + translationValue
			);

			containerElement.style.height = `${containerHeight}px`;
			const absRelativeHeight = Math.abs(viewportHeight - containerHeight);
			const x = (containerParams.bottom - containerHeight - absRelativeHeight) / absRelativeHeight;
			const translationY = -translationValue + x * -translationValue;

			objectElement.style.transform = `translateY(${translationY}px)`;
		});
	}

	render() {
		const {
			src,
			className,
			videoClassName,
			videoOptions,
		} = this.props;
		return (
			<div
				className={className || styles.Background}
				style={{ overflowY: 'hidden' }}
				ref={this.backgroundRef}
			>
				<video
					src={src}
					ref={this.videoRef}
					className={`${styles.Video} ${videoClassName}`}
					{...videoOptions}
				/>
			</div>
		);
	}
}

export default ParallaxVideo;
