import * as React from 'react';
import root from 'window-or-global';
import {
	desktopNavHeight,
	extraLargeDesktopBreakpoint,
	largeDesktopBreakpoint,
	mobileBreakpoint, mobileNavHeight,
	regularDesktopBreakpoint,
	smallDesktopBreakpoint,
	tabletBreakpoint,
} from 'common/consts/responsive';

export const isMobile = () => {
	return root.matchMedia(`(min-width: ${mobileBreakpoint}px)`).matches;
};

export const isTablet = () => {
	return root.matchMedia(`(min-width: ${tabletBreakpoint}px)`).matches;
};

export const isSmallDesktop = () => {
	return root.matchMedia(`(min-width: ${smallDesktopBreakpoint}px)`).matches;
};

export const isRegularDesktop = () => {
	return root.matchMedia(`(min-width: ${regularDesktopBreakpoint}px)`).matches;
};

export const isLargeDesktop = () => {
	return root.matchMedia(`(min-width: ${largeDesktopBreakpoint}px)`).matches;
};

export const isExtraLargeDesktop = () => {
	return root.matchMedia(`(min-width: ${extraLargeDesktopBreakpoint}px)`).matches;
};

export const useMediaMobile = () => {
	const [mediaMobile, setMediaMobile] = React.useState(false);
	React.useEffect(() => {
		const mediaFunction = () => setMediaMobile(isMobile());
		mediaFunction();
		root.addEventListener('resize', mediaFunction);
		return () => root.removeEventListener('resize', mediaFunction);
	}, [mediaMobile]);
	return mediaMobile;
};

export const useMediaTablet = () => {
	const [mediaTablet, setMediaTablet] = React.useState(false);
	React.useEffect(() => {
		const mediaFunction = () => setMediaTablet(isTablet());
		mediaFunction();
		root.addEventListener('resize', mediaFunction);
		return () => root.removeEventListener('resize', mediaFunction);
	}, [mediaTablet]);
	return mediaTablet;
};

export const useMediaSmallDesktop = () => {
	const [mediaSmallDesktop, setMediaSmallDesktop] = React.useState(false);
	React.useEffect(() => {
		const mediaFunction = () => setMediaSmallDesktop(isSmallDesktop());
		mediaFunction();
		root.addEventListener('resize', mediaFunction);
		return () => root.removeEventListener('resize', mediaFunction);
	}, [mediaSmallDesktop]);
	return mediaSmallDesktop;
};

export const useMediaRegularDesktop = () => {
	const [mediaRegularDesktop, setMediaRegularDesktop] = React.useState(false);
	React.useEffect(() => {
		const mediaFunction = () => setMediaRegularDesktop(isRegularDesktop());
		mediaFunction();
		root.addEventListener('resize', mediaFunction);
		return () => root.removeEventListener('resize', mediaFunction);
	}, [mediaRegularDesktop]);
	return mediaRegularDesktop;
};

export const useMediaLargeDesktop = () => {
	const [mediaLargeDesktop, setMediaLargeDesktop] = React.useState(false);
	React.useEffect(() => {
		const mediaFunction = () => setMediaLargeDesktop(isLargeDesktop());
		mediaFunction();
		root.addEventListener('resize', mediaFunction);
		return () => root.removeEventListener('resize', mediaFunction);
	}, [mediaLargeDesktop]);
	return mediaLargeDesktop;
};

export const useMediaExtraLargeDesktop = () => {
	const [mediaExtraLargeDesktop, setMediaExtraLargeDesktop] = React.useState(false);
	React.useEffect(() => {
		const mediaFunction = () => setMediaExtraLargeDesktop(isExtraLargeDesktop());
		mediaFunction();
		root.addEventListener('resize', mediaFunction);
		return () => root.removeEventListener('resize', mediaFunction);
	}, [mediaExtraLargeDesktop]);
	return mediaExtraLargeDesktop;
};

export const useNavigationHeight = () => {
	return useMediaTablet() ? desktopNavHeight : mobileNavHeight;
};
