import * as React from 'react';
import { renderMinutesAndSeconds } from 'common/methods/string';
import styles from './Timer.module.scss';

export interface TimerProps {
	counting: boolean;
	time: number; // in milliseconds
	timeRenderFunction?: (t: any) => any;
	onFinish?: () => any;
	className?: string;
}

const Timer: React.FC<TimerProps> = (props) => {
	const {
		timeRenderFunction,
		className,
		counting,
		onFinish,
		time,
	} = props;

	const [currentTime, setCurrentTime] = React.useState(time);

	React.useEffect(() => {
		const timeout = setTimeout(() => {
			if (counting && currentTime > 0) {
				setCurrentTime(currentTime - 1000);
			} else {
				setCurrentTime(0);
				onFinish();
			}
		}, 1000);
		return () => {
			clearTimeout(timeout);
		};
	}, [counting, currentTime]);

	return (
		<span className={className || styles.Time}>
			{timeRenderFunction(currentTime)}
		</span>
	);
};
Timer.defaultProps = {
	timeRenderFunction: renderMinutesAndSeconds,
	onFinish: () => null,
	className: '',
};

export default Timer;
