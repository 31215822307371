import * as React from 'react';
import NavigationModel from 'models/NavigationModel';
import LinkParser from 'components/LinkParser/LinkParser';
import OuterLink from 'blocks/OuterLink/OuterLink';
import NavLink from 'blocks/Navigation/NavLink/NavLink';
import MenuBurger from 'components/MenuBurger/MenuBurger';
import styles from './MobileNavigiation.module';

export interface NavigationProps {
    data: NavigationModel;
    scrollToTop: boolean;
}

const MobileNavigation: React.FC<NavigationProps> = ({ data, scrollToTop }) => {
    const [isOpened, toggleMenu] = React.useState(false);
    const {
		links,
		facebookLink,
        linkedinLink,
    } = data;
    
    return (
        <React.Fragment>
            <div
                onClick={() => toggleMenu(!isOpened)}
                className={styles.MenuBurgerContainer}
            >
                <MenuBurger isOpened={isOpened} />
            </div>
            <div className={`${styles.CollapedMenu}
                ${isOpened ? styles.MobileNavShow : styles.MobileNavHide}`}
            >
                <div className={styles.OpenedMenuContainer}>
                    {links && links.map((link) => (
                        <div 
                            onClick={() => toggleMenu(false)}
                            key={link.id}
                            className={styles.MobileLink}
                        >
                            <LinkParser
                                className={`${scrollToTop ? styles.BigLink : styles.Link }`}
                                data={link}
                            >
                                <NavLink
                                    hashText={link.navigator.hashText}
                                    className={styles.MobileNavLink}
                                >
                                    {link.text}
                                </NavLink>
                            </LinkParser>
                        </div>
                    ))}
                    {facebookLink && (
                        <OuterLink
                            data={facebookLink}
                            className={`${scrollToTop ? styles.BigIconLink : styles.IconLink}`}
                        >
                            <img
                                src={'assets/icons/facebook_icon.svg'}
                                alt={'facebook'}
                                className={styles.MobileIcon}
                            />
                        </OuterLink>
                    )}
                    {linkedinLink && (
                        <OuterLink
                            data={linkedinLink}
                            className={`${scrollToTop ? styles.BigIconLink : styles.IconLink}`}
                        >
                            <img
                                src={'/assets/icons/linkedin_icon.svg'}
                                alt={'LinkedIn'}
                                className={styles.MobileIcon}
                            />
                        </OuterLink>
                    )}	
                </div>					
            </div>
        </React.Fragment>
    );
};

export default MobileNavigation;
   
