import * as React from 'react';
import styles from './SliderSection.module';
import Layout from 'components/Layout/Layout';
import SliderSectionModel from 'models/FourColumnsSectionModel';
import Carousel from 'components/Carousel/Carousel';
import SliderSectionSlide from 'blocks/SliderSection/Slide/SliderSectionSlide';
import Dots from 'blocks/SliderSection/Dots/Dots';
import {
	useMediaTablet,
} from 'common/hooks/media';
import BlackNoiseBackground from 'components/BlackNoiseBackground/BlackNoiseBackground';
import TrackVisibility from 'react-on-screen';

export interface SliderSectionProps {
	data: SliderSectionModel;
}

const settings = {
	infinite: true,
	speed: 500,
	arrows : false,
	slidesToShow: 1,
	slidesToScroll: 1,
};

const SliderSection: React.FC<SliderSectionProps> = ({ data }) => {
	const {
		header,
		slides,
	} = data;

	const isTablet = useMediaTablet();
	const [slideIndex, setSlideIndex] = React.useState(0);
	const carousel = React.useRef(null);

	const nextSlide = (nextSlideIndex: number) => {
		if (carousel && carousel.current) {
			carousel.current.slickGoTo(nextSlideIndex);
		}
	};

	return (
		<section
			className={styles.Background}
		>
			<BlackNoiseBackground />
			<Carousel
				innerRef={carousel}
				className={styles.Carousel}
				settings={settings}
				beforeChange={(before, next) => {
					setSlideIndex(next);
				}}
				draggable={!isTablet}
				fade={isTablet}
			>
				{slides.map((slide) => (
					<SliderSectionSlide
						className={styles.Slide}
						key={slide.id}
						data={slide}
						onImageClick={isTablet ? () => nextSlide(slideIndex + 1) : null}
					/>
				))}
			</Carousel>
			<Layout>
				<TrackVisibility
					once={true}
					offset={500}
				>
					{({ isVisible }) => isVisible ? (
						<h2 className={styles.Header}>
							{header}
						</h2>
					) : (
						<h2 className={styles.HeaderNotVisible}>
							{header}
						</h2>
					)}
				</TrackVisibility>
				<Dots
					className={styles.Dots}
					count={slides.length}
					white={true}
					currentIndex={slideIndex}
					onSelect={(i) => nextSlide(i)}
				/>
			</Layout>
		</section>
	);
};

export default SliderSection;
