import * as React from 'react';
import SliderSectionSlideModel from 'models/SliderSectionSlideModel';
import Img from 'components/Img/Img';
import Layout from 'components/Layout/Layout';
import {
	useMediaTablet,
} from 'common/hooks/media';
import styles from './SliderSectionSlide.module';

export interface SliderSectionSlideProps {
	data: SliderSectionSlideModel;
	className?: string;
	onImageClick?: (e: any) => any;
}

const SliderSectionSlide: React.FC<SliderSectionSlideProps> = ({ data, className, onImageClick }) => {
	const {
		blueHeadingText,
		headerTop,
		headerBottom,
		images,
		mobileImages,
		text,
	} = data;

	const isTablet = useMediaTablet();

	return (
		<div className={`${className} ${styles.Container}`}>
			<Layout className={styles.Grid}>
				<div className={styles.TextContainer}>
					<header>
						<h3 className={styles.BlueHeadingText}>
							{blueHeadingText}
						</h3>
						<p className={styles.HeaderTop}>
							{headerTop}
						</p>
						<p className={styles.HeaderBottom}>
							{headerBottom}
						</p>
					</header>
					<p
						className={styles.Text}
					>
						{text}
					</p>
				</div>
			</Layout>
			<div
				className={`${styles.ImagesContainer} ${onImageClick ? styles.Clickable : ''}`}
				onClick={onImageClick}
			>
				{((!isTablet && mobileImages.length) ? mobileImages : images)
					.map((image, index) => (
						<Img
							className={styles.Image}
							src={image.url}
							alt={image.description}
							key={index}
						/>
				))}
			</div>
		</div>
	);
};

SliderSectionSlide.defaultProps = {
	className: '',
	onImageClick: null,
};

export default SliderSectionSlide;
