import * as React from 'react';
import KENTICO_TYPES from 'common/consts/kentico-types';
import Navigator from 'blocks/Navigator/Navigator';
import HeroSection from 'blocks/HeroSection/HeroSection';
import PartnersSection from 'blocks/PartnersSection/PartnersSection';
import VideoSection from 'blocks/VideoSection/VideoSection';
import ThreeColumnsSection from 'blocks/ThreeColumnsSection/ThreeColumnsSection';
import SmallColumnsSection from 'blocks/SmallColumnsSection/SmallColumnsSection';
import FourColumnsSection from 'blocks/FourColumnsSection/FourColumnsSection';
import SliderSection from 'blocks/SliderSection/SliderSection';
import ContactForm from 'blocks/ContactForm/ContactForm';
import ScrollSliderSection from 'blocks/ScrollSliderSection/ScrollSliderSection';
import RichTextWithTitles from 'blocks/RichTextWithTitles/RichTextWithTitles';
import HeaderSection from 'blocks/HeaderSection/HeaderSection';
import FeaturesSection from 'blocks/FeaturesSection/FeaturesSection';

export interface PageContentParserProps {
	content: any[];
}

export const PageContentParser: React.FC<PageContentParserProps> = ({ content }) => (
	<React.Fragment>
		{content && content.map((model) => {
			switch (model.type) {
				case KENTICO_TYPES.HERO_SECTION:
					return <HeroSection key={model.id} data={model} />;
				case KENTICO_TYPES.NAVIGATOR:
					return <Navigator key={model.id} data={model} />;
				case KENTICO_TYPES.PARTNERS_SECTION:
					return <PartnersSection key={model.id} data={model} />;
				case KENTICO_TYPES.SCROLL_SLIDER_SECTION:
					return <ScrollSliderSection key={model.id} data={model} />;
				case KENTICO_TYPES.VIDEO_SECTION:
					return <VideoSection key={model.id} data={model} />;
				case KENTICO_TYPES.THREE_COLUMNS_SECTION:
					return <ThreeColumnsSection key={model.id} data={model} />;
				case KENTICO_TYPES.SMALL_COLUMNS_SECTION:
					return <SmallColumnsSection key={model.id} data={model} />;
				case KENTICO_TYPES.FOUR_COLUMNS_SECTION:
					return <FourColumnsSection key={model.id} data={model} />;
				case KENTICO_TYPES.SLIDER_SECTION:
					return <SliderSection key={model.id} data={model} />;
				case KENTICO_TYPES.CONTACT_FORM:
					return <ContactForm key={model.id} data={model} />;
				case KENTICO_TYPES.RICH_TEXT_WITH_TITLES:
					return <RichTextWithTitles key={model.id} data={model} />;
				case KENTICO_TYPES.HEADER_SECTION:
					return <HeaderSection key={model.id} data={model} />;
				case KENTICO_TYPES.FEATURES_SECTION:
					return <FeaturesSection key={model.id} data={model} />;
				default:
					return null;
			}
		})
	}
	</React.Fragment>
);
