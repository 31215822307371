import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';
import KENTICO_TYPES from 'common/consts/kentico-types';
import { ColumnModel } from '.';

export default class FourColumnsSectionModel extends ContentItemWithResolver {
	static readonly type = KENTICO_TYPES.FOUR_COLUMNS_SECTION;

	get header(): string {
		return get(this, '_header.value', '');
	}

	get columns(): ColumnModel[] {
		return get(this, '_columns', []);
	}
}
