import * as React from 'react';
import styles from './Input.module';

export interface InputProps {
	label: string;
	valueHook: Array<string | any>;
	className?: string;
	required?: boolean;
	type?: string;
	pattern?: string;
}

const Input: React.FC<InputProps> = (props) => {
	const {
		className,
		label,
		valueHook,
		type,
		required,
		pattern,
	} = props;

	const [value, setValue] = valueHook;
	const [valid, setValid] = React.useState(true);
	const [focus, setFocus] = React.useState(false);
	const inputRef = React.useRef(null);

	React.useEffect(() => {
		if (inputRef && inputRef.current) {
			if (!required) {
				setValid(inputRef.current.checkValidity());
			}
		}
	}, [required]);

	React.useEffect(() => {
			if (type === 'textarea') {
				setTimeout(() => {
					inputRef.current.style.height = 'auto';
					inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
					if (value.length === 0) {
						inputRef.current.style.height = '32px';
					}
				}, 0);
			}
		},
		[value]);

	const onFocus = () => {
		setFocus(true);
	};

	const onBlur = () => {
		setFocus(false);
		setValid(inputRef.current.checkValidity());
	};

	const onChange = ({ target }) => {
		setValue(target.value);

		if (inputRef.current.checkValidity()) {
			setValid(true);
		}
	};

	return (
		<label className={`${className} ${styles.Container} ${!valid ? styles.Invalid : ''}`}>
			<span
				className={`${styles.Label} ${focus || value ? styles.Active : ''}`}
			>
				{label}
			</span>
			{type === 'textarea'
				? (
					<textarea
						ref={inputRef}
						required={required}
						className={styles.Input}
						value={value}
						onFocus={onFocus}
						onBlur={onBlur}
						onChange={onChange}
					/>
				)
				: (
					<input
						ref={inputRef}
						type={type}
						pattern={pattern}
						required={required}
						className={styles.Input}
						value={value}
						onFocus={onFocus}
						onBlur={onBlur}
						onChange={onChange}
					/>
				)}
		</label>
	);
};

Input.defaultProps = {
	className: '',
	type: 'text',
	pattern: null,
	required: false,
};

export default Input;
