import * as React from 'react';
import { withRouter } from 'react-router';
import { History } from 'history';
import NavigationModel from 'models/NavigationModel';
import Img from 'components/Img/Img';
import LinkParser from 'components/LinkParser/LinkParser';
import OuterLink from 'blocks/OuterLink/OuterLink';
import MediaTablet from 'components/Media/Tablet/MediaTablet';
import NavLink from 'blocks/Navigation/NavLink/NavLink';
import root from 'window-or-global';
import BlackNoiseBackground from 'components/BlackNoiseBackground/BlackNoiseBackground';
import {
	useDidMount,
} from 'common/hooks/common';
import { useMediaTablet } from 'common/hooks/media';
import MobileNavigation from './MobileNavigation/MobileNavigation';
import ANCHOR from 'common/consts/anchor';
import styles from './Navigation.module';

export interface NavigationProps {
	data: NavigationModel;
	history: History;
}

const Navigation: React.FC<NavigationProps> = ({ data, history }) => {
	const {
		logo,
		links,
		facebookLink,
		linkedinLink,
		contactLink,
	} = data;
	const didMount = useDidMount();
	const [scrollToTop, setScrollTopTop] = React.useState(true);

	const onClick = () => {
		if (root.location.pathname === ANCHOR.HOME) {
			root.scroll({ top: 0, left: 0, behavior: 'smooth' });
		}
		history.push(ANCHOR.HOME);
	}

	React.useEffect(() => {
		const onScroll = () => {
			if (root.scrollY === 0 && !scrollToTop) {
				setScrollTopTop(true);
			} else if (root.scrollY !== 0 && scrollToTop) {
				setScrollTopTop(false);
			}
		};
		root.addEventListener('scroll', onScroll);
		return () => root.removeEventListener('scroll', onScroll);
	},[didMount, scrollToTop]);

	return (
		<nav className={styles.Navigation}>
			<BlackNoiseBackground />
			{logo && (
				<Img
					className={`${scrollToTop ? styles.BigLogo : styles.Logo}`}
					src={logo.url}
					alt={logo.description}
					onClick={onClick}
				/>
			)}
			{!useMediaTablet() && (
				<MobileNavigation data={data} scrollToTop={scrollToTop} />
			)}
			<div className={styles.LinksContainer}>
				<MediaTablet>
					{links && links.map((link) => (
						<LinkParser
							className={`${scrollToTop ? styles.BigLink : styles.Link }`}
							key={link.id}
							data={link}
						>
							<NavLink
								hashText={link.navigator.hashText}
							>
								{link.text}
							</NavLink>
						</LinkParser>
					))}
					{facebookLink && (
						<OuterLink
							data={facebookLink}
							className={`${scrollToTop ? styles.BigIconLink: styles.IconLink}`}
						>
							<img
								src={'assets/icons/facebook_icon.svg'}
								alt={'facebook'}
							/>
						</OuterLink>
					)}
					{linkedinLink && (
						<OuterLink
							data={linkedinLink}
							className={`${scrollToTop ? styles.BigIconLink: styles.IconLink}`}
						>
							<img
								src={'/assets/icons/linkedin_icon.svg'}
								alt={'LinkedIn'}
							/>
						</OuterLink>
					)}
				</MediaTablet>
				{contactLink && (
					<LinkParser
						className={styles.ContactLinkContainer}
						data={contactLink}
					>
						<div className={`${scrollToTop ? styles.BigContactLink: styles.ContactLink}`}>
							{contactLink.text}
						</div>
					</LinkParser>
				)}
			</div>
		</nav>
	);
};

export default withRouter(Navigation);
