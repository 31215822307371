import * as React from 'react';
import ColumnWithLinkModel from 'models/ColumnWithLinkModel';
import Img from 'components/Img/Img';
import Button from 'components/Button/Button';
import LinkParser from 'components/LinkParser/LinkParser';
import styles from './ColumnWithLink.module';

export interface ColumnWithLinkProps {
	data: ColumnWithLinkModel;
	className?: string;
}

const ColumnWithLink: React.FC<ColumnWithLinkProps> = ({ data, className }) => {
	const {
		headerTop,
		headerBottom,
		image,
		text,
		link,
	} = data;

	return (
		<div className={`${className} ${styles.Container}`}>
			<header className={styles.HeaderContainer}>
				{image && (
					<Img
						className={styles.Image}
						src={image.url}
						alt={image.description}
					/>
				)}
				<h3 className={styles.HeaderTop}>
					{headerTop}
				</h3>
				{headerBottom && (
					<p className={styles.HeaderBottom}>
						{headerBottom}
					</p>
				)}
			</header>
			<p
				className={styles.Text}
			>
				{text}
			</p>
			<LinkParser
				data={link}
				noRel={true}
			>
				<Button
					className={styles.Button}
					iconClassName={styles.Icon}
					outline={true}
					icon={'assets/icons/arrowout_icon.svg'}
				>
					{link.text}
				</Button>
			</LinkParser>
		</div>
	);
};

ColumnWithLink.defaultProps = {
	className: '',
};

export default ColumnWithLink;
