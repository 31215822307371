import root from 'window-or-global';

export enum FORM_STATE {
	LOADING = 'loading',
	SUCCESS = 'success',
	ERROR = 'error',
	DEFAULT = 'default',
}

export const SUCCESS_MESSAGE_TIMEOUT = 3000; // milliseconds
export const RECORDING_TIME_MS = 60000; // milliseconds

export const isFacebookApp = () => {
	const ua = navigator.userAgent || navigator.vendor || root.opera;
	return (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1);
};
