import * as React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { CoreProvider } from 'stores/CoreStore';
import Page from 'containers/Page';
import { getDataFromURL } from 'services/inbound.service';

const App: React.FC<any> = () => {
	React.useEffect(() => {
		getDataFromURL()
	}, []);

	return (
		<Router>
			<CoreProvider>
				<Route path='/:slug?' component={Page} />
			</CoreProvider>
		</Router>
	);
};

export default App;
