import * as React from 'react';
import VideoSectionModel from 'models/VideoSectionModel';
import styles from './VideoSection.module';
import Button from 'components/Button/Button';
import { useMediaTablet } from 'common/hooks/media';
import ParallaxVideo from 'components/ParallaxVideo/ParallaxVideo';

export interface VideoSectionProps {
	data: VideoSectionModel;
}

const VideoSection: React.FC<VideoSectionProps> = ({ data }) => {
	const {
		placeholderVideo,
		mobilePlaceholderVideo,
		buttonText,
		videoUrl,
		mobileVideoUrl
	} = data;
	const mediaTablet = useMediaTablet();
    const [fullScreen, setFullScreen] = React.useState(false);

	const videoToDisplayUrl = mediaTablet ? videoUrl : mobileVideoUrl;
	const placeholderVideoToDisplay = mediaTablet ? placeholderVideo : mobilePlaceholderVideo;

	return (
		<section className={styles.Container}>

			{mediaTablet ? (
				<ParallaxVideo
					className={styles.Video}
					src={placeholderVideoToDisplay.url}
					videoOptions={{
						muted: true,
						autoPlay: true,
						loop: true,
						playsInline: true,
					}}
				/>
			) : (
				<video
					className={styles.Video}
					src={placeholderVideoToDisplay.url}
					muted={true}
					autoPlay={true}
					loop={true}
					playsInline={true}
				/>
			)}

			{videoToDisplayUrl && (
				<Button
					disabled={false}
					className={styles.Button}
					iconClassName={styles.ButtonIcon}
					icon={'/assets/icons/play_icon.svg'}
					onClick={() => setFullScreen(true)}
				>
					{buttonText}
				</Button>
			)}

            {fullScreen &&
                <div className={styles.FullScreenContainer}>
                    <div
                        className={styles.FullScreenBackground}
                        onClick={() => setFullScreen(false)}
                    />
                    <div className={styles.FullScreenAssetWrapper}>
                        <div className={styles.FullScreenAsset}>
                            <iframe className={styles.Iframe} src={mediaTablet ? videoUrl : mobileVideoUrl} frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            >
                            </iframe>
                        </div>
                    </div>
                </div>
            }

        </section>
	);
};

export default VideoSection;
