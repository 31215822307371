enum KENTICO_TYPES {
	SEO = 'seo',
	PAGE = 'page',
	FOOTER = 'footer',
	INNER_LINK = 'inner_link',
	OUTER_LINK = 'outer_link',
	NAVIGATION = 'navigation',
	NAVIGATOR = 'navigator',
	HERO_SECTION = 'hero_section',
	PARTNERS_SECTION = 'partners_section',
	VIDEO_SECTION = 'video_section',
	FOUR_COLUMNS_SECTION = 'four_columns_section',
	THREE_COLUMNS_SECTION = 'three_columns_section',
	COLUMN = 'column',
	COLUMN_WITH_LINK = 'column_with_link',
	SMALL_COLUMNS_SECTION = 'small_columns_section',
	SMALL_COLUMN = 'small_column',
	SLIDER_SECTION_SLIDE = 'slider_section_slide',
	SLIDER_SECTION = 'slider_section',
	CONTACT_FORM = 'contact_form',
	VOICE_RECORDER = 'voice_recorder',
	SCROLL_SLIDER_SECTION = 'scroll_slider_section',
	SCROLL_SLIDER_SECTION_SLIDE = 'scroll_slider_section_slide',
	RICH_TEXT_WITH_TITLES = 'rich_text_with_titles',
	CORE = 'core',
	HEADER_SECTION = 'header_section',
	TWO_COLUMNS_SECTION = 'two_columns',
	FEATURES_SECTION = 'features_section',
	COLUMN_WITH_FULL_IMAGE = 'column_with_full_screen_image',
	GLOBAL_META_TAGS = 'global_meta_tags',
	GLOBAL_META_TAG = 'global_meta_tag_element',
}

export default KENTICO_TYPES;
