import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';
import { Fields } from 'kentico-cloud-delivery/_es2015';
import RichTextField = Fields.RichTextField;
import KENTICO_TYPES from 'common/consts/kentico-types';

export default class RichTextWithTitlesModel extends ContentItemWithResolver {
    static readonly type = KENTICO_TYPES.RICH_TEXT_WITH_TITLES;

    get title(): string {
        return get(this, '_title.value', '');
    }

    get subtitle(): string {
        return get(this, '_subtitle.value', '');
    }

    get content(): RichTextField {
        return get(this, '_content', null);
    }

    get backHomeButtonText(): RichTextField {
        return get(this, '_back_home_button_text.value', '');
    }
}
