import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';
import { FieldModels } from 'kentico-cloud-delivery/_es2015';
import AssetModel = FieldModels.AssetModel;
import KENTICO_TYPES from 'common/consts/kentico-types';

export default class HeroSectionModel extends ContentItemWithResolver {
	static readonly type = KENTICO_TYPES.HERO_SECTION;

	get headerTopLine(): string {
		return get(this, '_header_top_line.value', '');
	}

	get description(): string {
		return get(this, '_description.value', '');
	}
	
	get image(): AssetModel {
		return get(this, '_image.assets[0]', null);
	}

	get headerBottomLine(): string {
		return get(this, '_header_bottom_line.value', '');
	}

	get smallText(): string {
		return get(this, '_small_text.value', '');
	}
}
