import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';
import KENTICO_TYPES from 'common/consts/kentico-types';
import SmallColumnModel from 'models/SmallColumnModel';

export default class SmallColumnsSectionModel extends ContentItemWithResolver {
	static readonly type = KENTICO_TYPES.SMALL_COLUMNS_SECTION;

	get header(): string {
		return get(this, '_header.value', '');
	}

	get text(): string {
		return get(this, '_text.value', '');
	}

	get smallColumns(): SmallColumnModel[] {
		return get(this, '_small_columns', []);
	}
}
