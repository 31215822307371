import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';
import { FieldModels } from 'kentico-cloud-delivery/_es2015';
import AssetModel = FieldModels.AssetModel;
import KENTICO_TYPES from 'common/consts/kentico-types';
import InnerLinkModel from 'models/InnerLinkModel';
import OuterLinkModel from 'models/OuterLinkModel';

export default class HeaderSectionModel extends ContentItemWithResolver {
    static readonly type = KENTICO_TYPES.HEADER_SECTION;

    get headerTop(): string {
        return get(this, '_header_top.value', '');
    }

    get headerBottom() : string {
        return get(this, '_header_bottom.value', '');
    }

    get text(): string {
        return get(this, '_text.value', '');
    }

    get link(): InnerLinkModel | OuterLinkModel {
		    return get(this, '_link[0]', null);
    }
    
    get image(): AssetModel {
		    return get(this, '_image.assets[0]', null);
    }
    
    get smallIcon(): AssetModel {
		    return get(this, '_small_icon.assets[0]', null);
    }

    get bigIcon1(): AssetModel {
		    return get(this, '_big_icon_1.assets[0]', null);
    }

    get mediumIcon(): AssetModel {
		    return get(this, '_medium_icon.assets[0]', null);
    }

    get bigIcon2(): AssetModel {
		    return get(this, '_big_icon_2.assets[0]', null);
    }
}
