import * as React from 'react';
import Asset from 'components/Asset/Asset';
import Img from 'components/Img/Img';
import Slider from 'react-slick';
import ScrollSliderSectionSlideModel from 'models/ScrollSliderSectionSlideModel';
import ArrowRight from 'components/ArrowRight/ArrowRight';
import styles from './FullScreenSlider.module';

export interface FullScreenSliderProps {
	setSlideIndex: (slideIndex: number) => any;
	setFullScreen: (isFullScreen: boolean) => any;
	slides: ScrollSliderSectionSlideModel[];
	slideIndex: number;
	parentSliderRef?: any;
}

const FullScreenSlider: React.FC<FullScreenSliderProps> = (props) => {
	const {
		setSlideIndex,
		setFullScreen,
		slides,
		slideIndex,
		parentSliderRef,
	} = props;

	const sliderRef = React.useRef(null);

	React.useEffect(() => {
		if (sliderRef && sliderRef.current) {
			sliderRef.current.slickGoTo(slideIndex, true);
		}
	}, [
		sliderRef,
		slideIndex,
	]);

	const slideRight = () => {
		if (sliderRef && sliderRef.current) {
			sliderRef.current.slickNext();
		}
	};

	const slideLeft = () => {
		if (sliderRef && sliderRef.current) {
			sliderRef.current.slickPrev();
		}
	};

	const onClose = () => {
		if (parentSliderRef) {
			parentSliderRef.slickGoTo(slideIndex);
		}
		setFullScreen(false);
	};

	return (
		<div
			className={styles.FullScreenContainer}
		>
			<div
				className={styles.FullScreenBackground}
				onClick={onClose}
			/>
			<Slider
				className={styles.FullScreenSlider}
				ref={sliderRef}
				infinite={false}
				speed={300}
				slidesToShow={1}
				beforeChange={(before, next) => {
					setSlideIndex(next);
				}}
			>
				{slides.map((slide) => (
					<Asset
						key={slide.id}
						className={styles.FullScreenAsset}
						asset={slide.asset}
					/>
				))}
			</Slider>
			{slideIndex > 0 && (
				<button
					type={'button'}
					className={styles.LeftSideArrowContainer}
					onClick={slideLeft}
				>
					<ArrowRight
						className={styles.ArrowLeft}
					/>
				</button>
			)}
			{slideIndex < (slides.length - 1) && (
				<button
					type={'button'}
					className={styles.RightSideArrowContainer}
					onClick={slideRight}
				>
					<ArrowRight
						className={styles.ArrowRight}
					/>
				</button>
			)}
			<div
				className={styles.CloseIconContainer}
				onClick={onClose}
			>
				<Img
					className={styles.CloseIcon}
					src={'/assets/icons/close_icon.svg'}
					alt={'close'}
				/>
			</div>
		</div>
	);
};

export default FullScreenSlider;
