import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';
import KENTICO_TYPES from 'common/consts/kentico-types';
import InnerLinkModel from 'models/InnerLinkModel';
import OuterLinkModel from 'models/OuterLinkModel';

export default class ThreeColumnsSectionModel extends ContentItemWithResolver {
	static readonly type = KENTICO_TYPES.THREE_COLUMNS_SECTION;

	get header(): string {
		return get(this, '_header.value', '');
	}

	get columns(): any[] {
		return get(this, '_columns', []);
	}

	get link(): InnerLinkModel | OuterLinkModel {
		return get(this, '_link[0]', null);
	}

	get mobileLink(): InnerLinkModel | OuterLinkModel {
		return get(this, '_mobile_link[0]', null);
	}

}
