import * as React from 'react';
import ColumnModel from 'models/ColumnModel';
import Img from 'components/Img/Img';
import styles from './Column.module';

export interface ColumnProps {
	data: ColumnModel;
	className?: string;
}

const Column: React.FC<ColumnProps> = ({ data, className }) => {
	const {
		headerTop,
		headerBottom,
		image,
		text,
	} = data;

	return (
		<div className={className}>
			<header className={styles.HeaderContainer}>
				<h3 className={styles.Header}>
					<span className={styles.HeaderBold}>
						{headerTop}
					</span>
					<br />
					{headerBottom}
				</h3>
				{image && (
					<Img
						className={styles.Image}
						src={image.url}
						alt={image.description}
					/>
				)}
			</header>
			<p
				className={styles.Text}
			>
				{text}
			</p>
		</div>
	);
};

Column.defaultProps = {
	className: '',
};

export default Column;
