import * as React from 'react';
import HeaderSectionModel from 'models/HeaderSectionModel';
import Layout from 'components/Layout/Layout';
import BlackNoiseBackground from 'components/BlackNoiseBackground/BlackNoiseBackground';
import Img from 'components/Img/Img';
import LinkParser from 'components/LinkParser/LinkParser';
import Button from 'components/Button/Button';
import styles from './HeaderSection.module.scss';

export interface HeaderSectionProps {
	data: HeaderSectionModel;
}

const HeaderSection: React.FC<HeaderSectionProps> = ({ data }) => {
    const {
        headerTop,
        headerBottom,
        text,
        link,
        image,
        smallIcon,
        bigIcon1,
        mediumIcon,
        bigIcon2,
    } = data;

    return (
        <section className={styles.Container}>
            <BlackNoiseBackground />
                <Layout className={styles.Layout}>
                    <div className={styles.TextContainer}>
                        <header>
                            <h2 className={styles.HeaderTop}>
                                {headerTop}
                            </h2>
                            <h2 className={styles.HeaderBottom}>
                                {headerBottom}
                            </h2>
                        </header>
                        <p
                            className={styles.Text}
                        >
                            {text}
                        </p>
                        <LinkParser
                            data={link}
                            noRel={true}
                        >
                            <Button
                                className={styles.Button}
                                outline={true}
                                iconElement={
                                    <span
                                        className={styles.ArrowIcon}
                                    />
                                }
                            >
                                {link.text}
                            </Button>
                        </LinkParser>
                    </div>
                </Layout>
            <div className={styles.ImagesContainer}>
                {image && (
					<Img
						className={styles.Image}
						src={image.url}
						alt={image.description}
					/>
                )}
            </div>
            <div className={styles.IconsContainer}>
                {smallIcon && (
                    <Img
                        className={styles.SmallIcon}
                        src={smallIcon.url}
                        alt={image.description}
                    />
                )}
                {bigIcon1 && (
                    <Img
                        className={styles.BigIcon1}
                        src={bigIcon1.url}
                        alt={image.description}
                    />
                )}
                {mediumIcon && (
                    <Img
                        className={styles.MediumIcon}
                        src={mediumIcon.url}
                        alt={image.description}
                    />
                )}
                {bigIcon2 && (
                    <Img
                        className={styles.BigIcon2}
                        src={bigIcon2.url}
                        alt={image.description}
                    />
                )}
            </div>
        </section>
    );
}

export default HeaderSection;
