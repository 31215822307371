import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';
import { Fields } from 'kentico-cloud-delivery';
import KENTICO_TYPES from 'common/consts/kentico-types';
import FooterModel from 'models/FooterModel';
import NavigationModel from 'models/NavigationModel';
import SEOModel from 'models/SEOModel';
import TaxonomyField = Fields.TaxonomyField;

export default class PageModel extends ContentItemWithResolver {
	static readonly type = KENTICO_TYPES.PAGE;

	get SEO(): SEOModel {
		return get(this, '_seo[0]', null);
	}

	get navigation(): NavigationModel {
		return get(this, '_navigation[0]', null);
	}

	get content(): ContentItemWithResolver[] {
		return get(this, '_content', []);
	}

	get footer(): FooterModel {
		return get(this, '_footer[0]', null);
	}

	get slug(): TaxonomyField {
		return get(this, '_slug.taxonomyTerms[0].name', '');
	}
}
