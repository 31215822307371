import * as React from 'react';
import FooterModel from 'models/FooterModel';
import Layout from 'components/Layout/Layout';
import LinkParser from 'components/LinkParser/LinkParser';
import OuterLink from 'blocks/OuterLink/OuterLink';
import MediaTablet from 'components/Media/Tablet/MediaTablet';
import styles from './Footer.module';

export interface FooterProps {
	data: FooterModel;
}

const Footer: React.FC<FooterProps> = ({ data }) => {
	const {
		footerText,
		findUsText,
		links,
		facebookLink,
		linkedinLink,
	} = data;

	return (
		<footer className={styles.Footer}>
			<Layout>
				<div className={styles.Container}>
					{links.map((link) => (
						<LinkParser
							className={styles.Link}
							key={link.id}
							data={link}
						>
							{link.text}
						</LinkParser>
					))}
				</div>
				<div className={`${styles.Container} ${styles.TextContainer}`}>
					<span className={styles.FooterText}>
						{footerText}
					</span>
				</div>
				<div className={`${styles.Container} ${styles.TextContainer}`}>
					<span className={styles.FooterText}>
						<a className={styles.Link} href="/investors" target="blank">For investors</a>					
					</span>
				</div>				
				<div className={`${styles.Container} ${styles.SocialContainer}`}>
					<span className={styles.FooterText}>
					{findUsText}
					</span>
					<div>
						{facebookLink && (
							<OuterLink
								data={facebookLink}
								className={styles.IconLink}
							>
								<img
									src={'assets/icons/facebook_icon.svg'}
								/>
							</OuterLink>
						)}
						{linkedinLink && (
							<OuterLink
								data={linkedinLink}
								className={styles.IconLink}
							>
								<img
									src={'/assets/icons/linkedin_icon.svg'}
								/>
							</OuterLink>
						)}
					</div>
				</div>
			</Layout>
		</footer>
	);
};

export default Footer;
