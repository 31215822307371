import * as React from 'react';
import root from 'window-or-global';
import styles from './AudioAnalyser.module.scss';
import throttle from 'lodash.throttle';

export interface AudioAnalyserProps {
	className?: string;
}

class AudioAnalyser extends React.Component<AudioAnalyserProps, any> {
	static defaultProps = {
		className: '',
	};

	get audioBandHeights() {
		return this.state.dataArray.map((volume, index) => (
			Math.min(Math.max((volume * (1 + index / this.bandIndexDivider)) / this.scalar,
				this.minBandHeight),
				this.maxBandHeight)));
	}

	state = {
		dataArray: new Array(56).fill(0),
		isAnalysing: false,
	};

	audioMediaSource = null;
	audioElementSource = null;
	analyser = null;
	audioContext = null;

	minBandHeight = 2;
	maxBandHeight = 25;

	// volume of each audio frequency band (the lower index -> the lower frequency)
	// lowest frequencies have often very big volumes, therefore, for visualisation,
	// bandIndexDivider is used to scale volumes to optimal level in visualisation.
	bandIndexDivider = 16;

	// the scalar is used for scaling every volume value to fit max and min height.
	scalar = 25;

	analyse = throttle(() => {
		if (this.analyser) {
			const data = new Uint8Array(this.analyser.frequencyBinCount);
			this.analyser.getByteFrequencyData(data);
			this.setState({ dataArray: Array.from(data).splice(0, 56) });
			if (this.state.isAnalysing) {
				root.requestAnimationFrame(this.analyse);
			} else {
				this.setState({ dataArray: new Array(56).fill(0) });
			}
		}
	}, 40);

	setAudioSource(streamOrElement) {
		if (!this.audioContext) {
			this.audioContext = new root.AudioContext();
			this.analyser = this.audioContext.createAnalyser();
			this.analyser.fftSize = 256;
		}
		if (streamOrElement instanceof root.Element) {
			if (!this.audioElementSource || this.audioElementSource.mediaElement !== streamOrElement) {
				this.audioElementSource = this.audioContext.createMediaElementSource(streamOrElement);
			}
			this.audioElementSource.connect(this.analyser);
			this.analyser.connect(this.audioContext.destination);
		} else {
			this.audioMediaSource = this.audioContext.createMediaStreamSource(streamOrElement);
			this.audioMediaSource.connect(this.analyser);
			this.analyser.disconnect();
		}
	}

	startAnalysing() {
		this.setState({ isAnalysing: true });
		this.analyse();
	}

	stopAnalysing() {
		this.setState({ isAnalysing: false });
	}

	render() {
		const { className } = this.props;

		return (
			<div className={className || styles.SoundBar}>
				<svg width={200} height={this.maxBandHeight * 2}>
					{this.audioBandHeights.map((height, index) => (
						<rect
							key={index}
							x={index * 4}
							y={this.maxBandHeight - height / 2}
							cy={this.maxBandHeight}
							width={2}
							height={height}
							style={{
								fill: '#292a32',
								fillOpacity: 0.4 + (height / this.maxBandHeight) * 0.6,
							}}
						/>
					))}
				</svg>
			</div>
		);
	}
}

export default AudioAnalyser;
