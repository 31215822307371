import get from 'lodash/get';
import ContentItemWithResolver from "models/ContentItemWithResolver";
import KENTICO_TYPES from 'common/consts/kentico-types';

export default class SEOModel extends ContentItemWithResolver {
	static readonly type = KENTICO_TYPES.SEO;

	get title() {
		return get(this, '_meta_tags__meta_title.value');
	}

	get description() {
		return get(this, '_meta_tags__meta_description.value');
	}

	get ogImage() {
		return get(this, '_meta_tags__meta_image.value[0].url');
	}

	get ogType() {
		return get(this, '_meta_tags__meta_type.value[0].name');
	}

	get metaKeywords() {
		return get(this, '_meta_tags__meta_keywords.value', '');
	}
}
