import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';
import KENTICO_TYPES from 'common/consts/kentico-types';
import VoiceRecorderModel from 'models/VoiceRecorderModel';
import {Fields} from 'kentico-cloud-delivery/_es2015';
import RichTextField = Fields.RichTextField;

export default class ContactFormModel extends ContentItemWithResolver {
	static readonly type = KENTICO_TYPES.CONTACT_FORM;

	get header(): string {
		return get(this, '_header.value', '');
	}

	get underHeaderText(): string {
		return get(this, '_under_header_text.value', '');
	}

	get name(): string {
		return get(this, '_name.value', '');
	}

	get company(): string {
		return get(this, '_company.value', '');
	}

	get email(): string {
		return get(this, '_email.value', '');
	}

	get phone(): string {
		return get(this, '_phone.value', '');
	}

	get message(): string {
		return get(this, '_message.value', '');
	}

	get processingDataCheckBox(): RichTextField {
		return get(this, '_processing_data_check_box', null);
	}

	get sendButtonText(): string {
		return get(this, '_send_button_text.value', '');
	}

	get useCaptcha(): boolean {
		return get(this, '_use_captcha.value[0].codename') === 'true'
	}

	get captchaErrorMessage(): string {
		return get(this, '_captcha_error_message.value')
	}

	get sendErrorText(): string {
		return get(this, '_send_error_text.value', '');
	}

	get sendSuccessText(): string {
		return get(this, '_send_success_text.value', '');
	}

	get sendLoadingText(): string {
		return get(this, '_send_loading_text.value', '');
	}

	get underButtonText(): string {
		return get(this, '_under_button_text.value', '');
	}

	get voiceRecorder(): VoiceRecorderModel {
		return get(this, '_voice_recorder[0]', {});
	}
}
