import * as React from 'react';
import OuterLinkModel from 'models/OuterLinkModel';

export interface OuterLinkProps {
	data: OuterLinkModel;
	className?: string;
	noRel?: boolean;
}

const OuterLink: React.FC<OuterLinkProps> = ({ data, className, noRel, children }) => {
	if (!data) {
		return null;
	}
	return (
		<a
			className={className}
			href={data.link}
			rel={noRel ? null : 'noopener noreferrer'}
			target={'_blank'}
		>
			{children || data.text}
		</a>
	);
};

OuterLink.defaultProps = {
	className: '',
	noRel: false,
};

export default OuterLink;
