import * as React from 'react';
import styles from './BlackNoiseBackground.module';

export interface BlackNoiseBackgroundProps {
	className?: string;
}

const BlackNoiseBackground: React.FC<BlackNoiseBackgroundProps> = ({ className }) => (
	<div
		className={`${className} ${styles.Noise}`}
	>
		<div
			className={styles.Gradient}
		/>
	</div>
);

BlackNoiseBackground.defaultProps = {
	className: '',
};

export default BlackNoiseBackground;
