import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';
import KENTICO_TYPES from 'common/consts/kentico-types';
import InnerLinkModel from 'models/InnerLinkModel';
import OuterLinkModel from 'models/OuterLinkModel';
import { FieldModels } from 'kentico-cloud-delivery/_es2015';
import AssetModel = FieldModels.AssetModel;

export default class NavigationModel extends ContentItemWithResolver {
	static readonly type = KENTICO_TYPES.NAVIGATION;

	get logo(): AssetModel {
		return get(this, '_logo.assets[0]', null);
	}

	get links(): InnerLinkModel | OuterLinkModel {
		return get(this, '_links', []);
	}

	get facebookLink(): OuterLinkModel {
		return get(this, '_facebook_link[0]', null);
	}

	get linkedinLink(): OuterLinkModel {
		return get(this, '_linkedin_link[0]', null);
	}

	get contactLink(): InnerLinkModel | OuterLinkModel {
		return get(this, '_contact_link[0]', null);
	}
}
