import * as React from 'react';

export interface ArrowRightProps {
	className?: string;
}

const ArrowRight: React.FC<ArrowRightProps> = ({ className }) => (
	<svg
		className={className}
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 15.9 30.4'
	>
		<defs>
			<style>.cls-1{'{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.4px;}'}</style>
		</defs>
		<title>arrow_left_right_icon</title>
		<line className='cls-1' x1='0.7' y1='15.2' x2='15.2' y2='0.7'/>
		<line className='cls-1' x1='0.7' y1='15.2' x2='15.2' y2='29.7'/>
	</svg>
);

ArrowRight.defaultProps = {
	className: '',
};

export default ArrowRight;
