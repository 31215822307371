import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';
import KENTICO_TYPES from 'common/consts/kentico-types';
import PageModel from 'models/PageModel';

export default class CoreModel extends ContentItemWithResolver {
	static readonly type = KENTICO_TYPES.CORE;

	get pageNotFound(): PageModel {
		return get(this, '_page_not_found[0]', null);
	}
}
