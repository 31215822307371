import * as React from 'react';
import KENTICO_TYPES from 'common/consts/kentico-types';
import InnerLink from 'blocks/InnerLink/InnerLink';
import OuterLink from 'blocks/OuterLink/OuterLink';

export interface LinkParserProps {
	data: any;
	className?: string;
	noRel?: boolean;
}

const LinkParser: React.FC<LinkParserProps> = ({ data, className, noRel, children }) => {
	if (!data) {
		return null;
	}
	switch (data.type) {
		case KENTICO_TYPES.INNER_LINK:
		return (
			<InnerLink
				data={data}
				className={className}
				children={children}
			/>
		);
		case KENTICO_TYPES.OUTER_LINK:
		return (
			<OuterLink
				data={data}
				className={className}
				children={children}
				noRel={noRel}
			/>
		);
		default:
			return null;
	}
};

export default LinkParser;
