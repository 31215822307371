import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';
import KENTICO_TYPES from 'common/consts/kentico-types';

export default class SliderSectionSlideModel extends ContentItemWithResolver {
	static readonly type = KENTICO_TYPES.SLIDER_SECTION;

	get header(): string {
		return get(this, '_header.value', '');
	}

	get slides(): SliderSectionSlideModel[] {
		return get(this, '_slides', []);
	}
}
