export enum POSITION  {
    LEFT ='left',
    RIGHT = 'right',
};

export const IMAGE_POSITION = {
    [POSITION.RIGHT]: 'row-reverse',
    [POSITION.LEFT]: 'row',
};

export enum COLOR {
    WHITE = 'white',
    BLACK = 'black',
    PRIMARY = 'primary',
    GREY ='secondary',
  }
  
export const COLOR_VALUES = {
    [COLOR.WHITE]: '#FFFFFF',
    [COLOR.BLACK]:'#191919',
    [COLOR.PRIMARY]: '#05A4EF',
    [COLOR.GREY]: '#B5B5B5',
};
