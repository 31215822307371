import * as React from 'react';
import CoreContext from 'stores/CoreStore';
import styles from './NavLink.module';

export interface NavLinkProps {
	className?: string;
	hashText: string;
}

const NavLink: React.FC<NavLinkProps> = ({ className, hashText, children }) => {
	const [{ activeHash }] = React.useContext(CoreContext);

	return (
		<span className={`${className} ${styles.NavLink} ${activeHash === hashText ? styles.Active : ''}`}>
			{children}
		</span>
	);
};

NavLink.defaultProps = {
	className: '',
	hashText: '',
};

export default NavLink;
