import * as React from 'react';
import ScrollSliderSectionSlideModel from 'models/ScrollSliderSectionSlideModel';
import Asset from 'components/Asset/Asset';
import {
	FieldModels,
} from 'kentico-cloud-delivery';
import AssetModel = FieldModels.AssetModel;
import Img from 'components/Img/Img';
import styles from './ScrollSliderSectionSlide.module';

export interface ScrollSliderSectionSlideProps {
	data: ScrollSliderSectionSlideModel;
	onFullScreen?: (asset: AssetModel) => any;
}

const ScrollSliderSectionSlide: React.FC<ScrollSliderSectionSlideProps> = (props) => {
	const {
		data: {
			asset,
			text,
		},
		onFullScreen,
	} = props;

	return (
		<div className={styles.Container}>
			<div
				className={styles.SlideContainer}
			>
				{onFullScreen && (
					<div
						className={styles.FullScreenIconContainer}
						onClick={() => onFullScreen(asset)}
					>
						<Img
							className={styles.FullScreenIcon}
							src={'/assets/icons/fullscreen_icon.svg'}
							alt={'fullscreen'}
						/>
					</div>
				)}
				<Asset
					asset={asset}
					className={styles.Asset}
				/>
			</div>
			<p
				className={styles.Text}
			>
				{text}
			</p>
		</div>
	);
};

ScrollSliderSectionSlide.defaultProps = {
	onFullScreen: null,
};

export default ScrollSliderSectionSlide;
