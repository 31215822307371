import * as React from 'react';
import styles from './MenuBurger.module';

export interface MenuBurgerProps {
    isOpened: boolean;
}

const MenuBurger: React.FC<MenuBurgerProps> = ({ isOpened }) => (
    <div
        className={`${styles.MenuBurger} 
            ${isOpened ? styles.BurgerOpen : ''}`
        } 
    />
)

export default MenuBurger;
