import * as React from 'react';
import TwoColumnsSectionModel from 'models/TwoColumnsSectionModel';
import BackgroundAndImagePosition from 'components/BackgroundAndImagePosition/BackgroundAndImagePosition';
import Asset from 'components/Asset/Asset';
import Layout from 'components/Layout/Layout';
import { POSITION, COLOR } from 'common/consts/common';
import styles from './TwoColumnsSection.module.scss';

export interface TwoColumnsSectionProps {
    data: TwoColumnsSectionModel;
}

const TwoColumnsSection: React.FC<TwoColumnsSectionProps> = ({ data }) => {
    const {
        headerTop,
        headerBottom,
        text,
        smallImage,
        description,
        image,
        backgroundColor,
        textColor,
        imagePosition,
        imageSize,
    } = data;

    return (
       <BackgroundAndImagePosition
            data={{backgroundColor, textColor, imagePosition}}
            className={styles.Container}
       >
            <Layout>
                <div className={`${styles.TextContainer} 
                    ${imagePosition === POSITION.LEFT && styles.TextLeftContainer }`}>
                    <h2 className={styles.HeaderTop}>
                        {headerTop}
                    </h2>
                    <h2 className={styles.headerBottom}>
                        {headerBottom}
                    </h2>
                    <p className={`${styles.Text} 
                        ${backgroundColor === COLOR.BLACK && styles.TextColor}`}>
                        {text}
                    </p>
                    <p 
                        className={styles.SmallText}
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                </div>
            </Layout>
            <div className={`${styles.ImageContainer}
                ${imagePosition !== POSITION.LEFT && styles.ImageLeftContainer}`}
            >
                {smallImage && (
                    <Asset  
                        className={styles.SmallImage}
                        asset={smallImage}
                    />
                )}
                {image && (
                    <Asset
                        className={`${imageSize
                            ? styles.Image 
                            : styles.ImageContain
                            }`}
                        asset={image}

                    />
                )}
            </div>
       </BackgroundAndImagePosition>
    );
}

export default TwoColumnsSection;
