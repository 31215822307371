import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';
import KENTICO_TYPES from 'common/consts/kentico-types';
import TwoColumnsSection from 'models/TwoColumnsSectionModel'

export default class FeaturesSection extends ContentItemWithResolver {
    static readonly type = KENTICO_TYPES.FEATURES_SECTION;

    get features(): TwoColumnsSection[] {
        return get(this, '_features', []);
    }
}
