import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';
import KENTICO_TYPES from 'common/consts/kentico-types';
import { FieldModels } from 'kentico-cloud-delivery/_es2015';
import AssetModel = FieldModels.AssetModel;

export default class ColumnModel extends ContentItemWithResolver {
	static readonly type = KENTICO_TYPES.COLUMN;

	get headerTop(): string {
		return get(this, '_header_top.value', '');
	}

	get headerBottom(): string {
		return get(this, '_header_bottom.value', '');
	}

	get image(): AssetModel {
		return get(this, '_image.assets[0]', null);
	}

	get text(): string {
		return get(this, '_text.value', '');
	}
}
