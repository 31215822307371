import * as React from 'react';
import isNil from 'lodash/isNil';
import wasmURL from 'common/consts/voiceMessage';
import root from 'window-or-global';
import Timer from 'components/VoiceRecorder/Timer/Timer';
import { RECORDING_TIME_MS } from 'common/consts/form';
import AudioAnalyser from 'components/VoiceRecorder/AudioAnalyser/AudioAnalyser';
import VoiceRecorderModel from 'models/VoiceRecorderModel';
import styles from './VoiceRecorder.module.scss';
import Button from 'components/Button/Button';

export interface VoiceRecorderProps {
	valueHook: Array<string | any>;
	className?: string;
	data?: VoiceRecorderModel;
}

const VoiceRecorder: React.FC<VoiceRecorderProps> = (props) => {
	const {
		valueHook,
		className,
		data: {
			textBeforeButton,
			textAfterButton,
			stopRecordingButtonText,
			recordButtonText,
			recordNewButtonText,
		},
	} = props;

	const buildString = (recording, nil) => {
		if (recording) {
			return stopRecordingButtonText;
		}
		if (nil) {
			return recordButtonText;
		}
		return recordNewButtonText;
	};

	const [value, setValue] = valueHook;
	const [isRecording, setIsRecording] = React.useState(false);
	const [isPlaying, setIsPlaying] = React.useState(false);
	const [recorder, setRecorder] = React.useState(null);
	const [audioPlayer] = React.useState(new root.Audio());

	const audioAnalyserRef = React.useRef(null);

	React.useEffect(() => {
		audioPlayer.addEventListener('ended', stopPlayer);
		return () => {
			audioPlayer.removeEventListener('ended', stopPlayer);
		};
	}, [audioPlayer]);

	React.useEffect(() => {
		if (recorder) {
			startRecording();
		}
	}, [recorder]);

	const lazyRecord = async () => {
		if (recorder) {
			startRecording();
		} else {
			const vmsg = (await import('vmsg')) as any;
			setRecorder(new vmsg.Recorder({ wasmURL }));
		}
	};

	const stopPlayer = () => {
		audioPlayer.pause();
		setIsPlaying(false);
	};

	const startPlayer = () => {
		if (audioAnalyserRef && audioAnalyserRef.current) {
			audioAnalyserRef.current.setAudioSource(audioPlayer);
			audioAnalyserRef.current.startAnalysing();
		}
		audioPlayer.play();
		setIsPlaying(true);
	};

	const startRecording = async () => {
		if (recorder) {
			try {
				await recorder.initAudio();
				await recorder.initWorker();
				recorder.startRecording();
				setIsPlaying(false);
				setIsRecording(true);
				if (audioAnalyserRef && audioAnalyserRef.current) {
					audioAnalyserRef.current.setAudioSource(recorder.stream);
					audioAnalyserRef.current.startAnalysing();
				}
			} catch (e) {
				console.error(e);
			}
		}
	};

	const stopRecording = async () => {
		await createBlobAfterRecording();
	};

	const createBlobAfterRecording = async () => {
		const blob = await recorder.stopRecording();
		setIsRecording(false);
		setValue(blob);
		audioPlayer.src = URL.createObjectURL(blob);
	};

	const removeRecording = () => {
		setValue(null);
		stopPlayer();
		setIsRecording(false);
	};

	return (
		<div className={`${styles.Container} ${className}`}>
			<div className={styles.AudioRecorder}>
				{textBeforeButton && <span className={styles.TextBeforeButton}>{textBeforeButton}</span>}
				<Button
					className={styles.RecordButton}
					onClick={!isRecording ? lazyRecord : stopRecording}
					iconElement={
						<span
							className={isRecording
								? styles.StopRecordingIcon
								: styles.RecordIcon}
						/>
					}
				>
					<span className={styles.RecordButtonText}>
						{buildString(isRecording, isNil(value))}
					</span>
				</Button>
				{textAfterButton && <span className={styles.TextAfterButton}>{textAfterButton}</span>}
			</div>

			<div className={styles.AudioPlayer}>
				<AudioAnalyser
					ref={audioAnalyserRef}
					className={
						isRecording || !isNil(value)
							? null
							: styles.HiddenButton
					}
				/>
				{isRecording && (
					<Timer
						time={RECORDING_TIME_MS}
						onFinish={stopRecording}
						counting={isRecording}
					/>
				)}

				{isPlaying
					? (
						<Button
							onClick={stopPlayer}
							className={
								!isRecording && !isNil(value)
									? `${styles.AudioButton} ${styles.PauseButton}`
									: styles.HiddenButton
							}
						/>
					) : (
						<Button
							onClick={startPlayer}
							className={
								!isRecording && !isNil(value)
									? `${styles.AudioButton} ${styles.PlayButton}`
									: styles.HiddenButton
							}
						/>
					)
				}

				<Button
					onClick={removeRecording}
					className={
						!isRecording && !isNil(value)
							? `${styles.AudioButton} ${styles.DeleteButton}`
							: styles.HiddenButton
					}
				/>
			</div>
		</div>
	);
};

VoiceRecorder.defaultProps = {
	className: '',
};

export default VoiceRecorder;
