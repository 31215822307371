import root from 'window-or-global';
import convertBlobToBase64 from 'common/methods/convertBlobToBase64';
import getCountryName from './country.service';

const URLContactForm = process.env.REACT_APP_CONTACT_FORM_MAIL_TO;
const URLPipdrive = process.env.REACT_APP_PIPDRIVE_FORM || 'https://thg-d-www-pipedrive-fap.azurewebsites.net/api/postnote?code=Ils3YzEnC4e_eucIqh-bmAFyZfiILKHctzHGfhApQ46-AzFun4AhSA%3D%3D';

class MailingService {
	static async sendContactForm({
		name, email, company, phone, message, url, source, referrer, voiceMessage, country
	}) {
		if (voiceMessage) {
			voiceMessage = await convertBlobToBase64(voiceMessage);
		}

		const dataPipdrive = {
			method: 'POST',
			body: JSON.stringify({
				name: 'Filip Szczęsny',
				content: {
					name,
					email,
					company,
					phone,
					message,
					country,
					url,
					source,
					referrer,
					voiceMessage: voiceMessage ? 'Yes, voice message included' : '',
				},
				person: {
					name,
					email,
					phone,
					country: getCountryName(country),
				},
				marketingAgreement: false,
				inbound: (typeof window !== 'undefined' && localStorage.getItem('inbound')) || 'Inbound - Unidentified',
				businessUnit: 'Holo4Labs',
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		}

		const dataMail = {
			method: 'POST',
			body: JSON.stringify({
				name,
				email,
				company,
				phone,
				message,
				country,
				url,
				source,
				referrer,
				voiceMessage,
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		}

		try {
			root.fetch(URLPipdrive, dataPipdrive);
			await root.fetch(URLContactForm, dataMail);
			return true;
		} catch {
			return false;
		}
	}
}

export default MailingService;
