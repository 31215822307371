import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';
import KENTICO_TYPES from 'common/consts/kentico-types';

export default class NavigatorModel extends ContentItemWithResolver {
	static readonly type = KENTICO_TYPES.NAVIGATOR;

	get hashText(): string {
		return get(this, '_hash_text.value', '');
	}
}
