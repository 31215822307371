import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';

export default class ContentItemWithColorAndPostitionModel extends ContentItemWithResolver {

    get textColor(): string {
      return get(this, '_text_color_picker__text_color.value[0].codename', null);
    }
  
    get backgroundColor(): string {
      return get(this, '_background_color_picker__background_color.value[0].codename', null);
    }

    get imagePosition(): string {
        return get(this, '_image_position_picker__image_position.value[0].codename', null);
    }
  }
