import * as React from 'react';
import { Link } from 'react-router-dom';

import Layout from 'components/Layout/Layout';
import RichTextWithTitlesModel from 'models/RichTextWithTitlesModel';
import styles from './RichTextWithTitles.module.scss';

export interface RichTextWithTitlesProps {
	data: RichTextWithTitlesModel;
}

const RichTextWithTitles: React.FC<RichTextWithTitlesProps> = ({ data }) => {
	const processingDataSafeHtml = data.content
		? data.content.getHtml()
		: '';
	return (
		<Layout>
			<article className={styles.Container}>
				<h1 className={styles.Title}>
					{data.title}
				</h1>
				<h2 className={styles.Title}>
					{data.subtitle}
				</h2>
				<div
					className={styles.Content}
					dangerouslySetInnerHTML={{ __html: processingDataSafeHtml }}
				/>
				<Link to='/' className={styles.BackHomeButton}>
					{data.backHomeButtonText}
				</Link>
			</article>
		</Layout>
	);
};

export default RichTextWithTitles;
