import * as React from 'react';
import Asset from 'components/Asset/Asset';
import Img from 'components/Img/Img';
import {
	FieldModels,
} from 'kentico-cloud-delivery';
import AssetModel = FieldModels.AssetModel;
import styles from './FullScreenColumn.module';

export interface FullScreenColumnProps {
	asset: AssetModel;
	setFullScreen: (isFullScreen: boolean) => any;
}

const FullScreenColumn: React.FC<FullScreenColumnProps> = (props) => {
	const {
        asset,
        setFullScreen,
	} = props;

	const onClose = () => {
		setFullScreen(false);
	};

	return (
		<div
			className={styles.FullScreenContainer}
		>
			<div
				className={styles.FullScreenBackground}
				onClick={onClose}
			/>
				<Asset
                    className={styles.FullScreenAsset}
                    asset={asset}
				/>
				
			<div
				className={styles.CloseIconContainer}
				onClick={onClose}
			>
				<Img
					className={styles.CloseIcon}
					src={'/assets/icons/close_icon.svg'}
					alt={'close'}
				/>
			</div>
		</div>
	);
};

export default FullScreenColumn;
