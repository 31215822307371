import * as React from 'react';
import FeaturesSectionModel from 'models/FeaturesSectionModel';
import TwoColumnsSection from './TwoColumnsSection/TwoColumnsSection';

export interface FeaturesSectionProps {
    data: FeaturesSectionModel;
}

const FeaturesSection: React.FC<FeaturesSectionProps> = ( { data }) => {
    const { features } = data;

    return (
        <section>
            {features && features.map(feature=> (
                <TwoColumnsSection  
                    data={feature} 
                    key={feature.system.id}
                />
            ))}
        </section>
    );
}

export default FeaturesSection;
