import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';
import KENTICO_TYPES from 'common/consts/kentico-types';
import ScrollSliderSectionSlideModel from 'models/ScrollSliderSectionSlideModel';
import { FieldModels } from 'kentico-cloud-delivery';
import AssetModel = FieldModels.AssetModel;

export default class ScrollSliderSectionModel extends ContentItemWithResolver {
  static readonly type = KENTICO_TYPES.SCROLL_SLIDER_SECTION;

  get backgroundImage(): AssetModel {
    return get(this, '_background_image.assets[0]', null);
  }

  get slides(): ScrollSliderSectionSlideModel[] {
    return get(this, '_slides', []);
  }
}
