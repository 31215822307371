import ContentItemWithColorAndPostitionModel
    from 'models/ContentItemWithColorAndPostitionModel';
import get from 'lodash/get';
import KENTICO_TYPES from 'common/consts/kentico-types';
import { FieldModels } from 'kentico-cloud-delivery/_es2015';
import AssetModel = FieldModels.AssetModel;

export default class TwoColumnsSection extends ContentItemWithColorAndPostitionModel  {
    static readonly type = KENTICO_TYPES.TWO_COLUMNS_SECTION;

    get headerTop(): string {
        return get(this, '_header_top.value', '');
    }

    get headerBottom() : string {
        return get(this, '_header_bottom.value', '');
    }

    get text(): string {
        return get(this, '_text.value', '');
    }

    get smallText(): string {
        return get(this, '_small_text.value', '');
    }

    get description(): string {
        return get(this, '_description.value', '');
    }

    get smallImage(): AssetModel {
        return get(this, '_small_image.assets[0]', null);
    }

    get image(): AssetModel {
        return get(this, '_image.assets[0]', null);
    }

    get imageSize(): string {
        return get(this,'_image_object_position__image_size.value[0].codename', null);
    }
}
