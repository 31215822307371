import * as React from 'react';
import Media from 'react-media';
import MediaProps from 'components/Media/MediaProps';

const MediaSmallDesktop: React.FC<MediaProps> = ({ notMatches, children }) => {
	return (
		<Media query={{ minWidth: 1100 }}>
			{(matches) => matches ? children : notMatches}
		</Media>
	);
};

MediaSmallDesktop.defaultProps = {
	notMatches: null,
};

export default MediaSmallDesktop;
