import {
	ImageCompressionEnum,
	ImageFormatEnum,
	ImageUrlBuilder,
} from 'kentico-cloud-delivery';
import supportsWebP from 'supports-webp';

export const buildPhotoURL = async (photoWidth: number, url: string) => {
	const urlBuilder = new ImageUrlBuilder(url);
	urlBuilder.withWidth(photoWidth);
	if (await supportsWebP) {
		urlBuilder
			.withFormat(ImageFormatEnum.Webp)
			.withCompression(ImageCompressionEnum.Lossy);
	}
	return urlBuilder.getUrl();
};
