import * as React from 'react';
import * as ReactDOM from 'react-dom';
import objectFitImages from 'object-fit-images';
import App from './App';
import 'styles/global';
import TagManager from 'react-gtm-module';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const hasGoogleTagManagerID = !!process.env.REACT_APP_TAG_MANAGER_ID;

const tagManagerArgs = {
	gtmId: process.env.REACT_APP_TAG_MANAGER_ID,
};

if (hasGoogleTagManagerID) {
	TagManager.initialize(tagManagerArgs);
}

objectFitImages();

ReactDOM.render (
	<App />,
	document.getElementById('root'),
);
