import * as React from 'react';
import Layout from 'components/Layout/Layout';
import Column from 'blocks/FourColumnsSection/Column/Column';
import FourColumnsSectionModel from 'models/FourColumnsSectionModel';
import MediaSmallDesktop from 'components/Media/SmallDesktop/MediaSmallDesktop';
import Carousel from 'components/Carousel/Carousel';
import TrackVisibility from 'react-on-screen';
import {
	useMediaMobile,
} from 'common/hooks/media';
import styles from './FourColumnsSection.module';

export interface FourColumnsSectionProps {
	data: FourColumnsSectionModel;
}

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	dotsClass: 'custom-slick-dots-black',
	slidesToShow: 1,
	slidesToScroll: 1,
};

const FourColumnsSection: React.FC<FourColumnsSectionProps> = ({ data }) => {
	const {
		header,
		columns,
	} = data;

	const mediaMobile = useMediaMobile();

	return (
		<section
			className={styles.Background}
		>
			<Layout>
				{mediaMobile ? (
					<TrackVisibility once={true}>
						{({ isVisible }) => isVisible ? (
							<h2 className={styles.Header}>
								{header}
							</h2>
						) : (
							<h2 className={styles.HeaderNotVisible}>
								{header}
							</h2>
						)}
					</TrackVisibility>
				) : (
					<h2 className={styles.Header}>
						{header}
					</h2>
				)}

				<MediaSmallDesktop
					notMatches={(
						<Carousel
							className={styles.Carousel}
							settings={settings}
						>
							{columns.map((column) => (
								<Column
									className={styles.Column1}
									key={column.id}
									data={column}
								/>
							))}
						</Carousel>
					)}
				>
					<div className={styles.Columns}>
						{columns.map((column) => (
							<Column
								className={`
									${columns.length === 4 ? styles.Column4 : ''}
									${columns.length === 3 ? styles.Column3 : ''}
									${columns.length === 2 ? styles.Column2 : ''}
									${columns.length === 1 ? styles.Column1 : ''}
								`}
								key={column.id}
								data={column}
							/>
						))}
					</div>
				</MediaSmallDesktop>

			</Layout>
		</section>
	);
};

export default FourColumnsSection;
