import * as React from 'react';
import SmallColumnModel from 'models/SmallColumnModel';
import Img from 'components/Img/Img';
import HorizontalLine from 'components/HorizontalLine/HorizontalLine';
import styles from './SmallColumn.module';

export interface SmallColumnProps {
	data: SmallColumnModel;
	className?: string;
}

const SmallColumn: React.FC<SmallColumnProps> = ({ data, className }) => {
	const {
		image,
		text,
	} = data;

	return (
		<div className={className}>
			<Img
				className={styles.Image}
				src={image.url}
				alt={image.description}
			/>
			<HorizontalLine
				className={styles.HorizontalLine}
			/>
			<p className={styles.Text}>
				{text}
			</p>
		</div>
	);
};

SmallColumn.defaultProps = {
	className: '',
};

export default SmallColumn;
