import * as React from 'react';
import Slider from 'react-slick';

const Carousel: React.FC<any> = (props) => {
	const {
		settings,
		innerRef,
		className,
		children,
	} = props;

	return (
		<div>
			<Slider
				{...settings}
				ref={innerRef}
				{...props}
				className={className}
			>
				{children}
			</Slider>
		</div>
	);
};

Carousel.defaultProps = {
	className: '',
};

export default Carousel;
