import * as React from 'react';
import { withRouter } from 'react-router';
import throttle from 'lodash.throttle';
import root from 'window-or-global';
import { Store } from 'stores/types';
import { CoreService } from 'services/core-service';
import PageModel from 'models/PageModel';
import NavigatorModel from 'models/NavigatorModel';
import GlobalMetaTagsModel from 'models/GlobalMetaTagsModel';

export interface CoreStoreData {
	page: PageModel;
	navigators: Array<React.MutableRefObject<NavigatorModel>>;
	activeHash: string;
	scrollToSection: React.MutableRefObject<NavigatorModel>;
	smoothScroll: boolean;
	isLoaded: boolean;
	pageNotFoundData: PageModel;
	urlHistory: {
		source: any;
		url: any;
		referrer: any;
	};
	globalMetaTags: GlobalMetaTagsModel;
}

class Provider extends React.Component<any, any> {
	setActiveHash = throttle(() => {
		const { navigators } = this.state;
		const currentNavigators = navigators.filter((navigator) => navigator.current);

		const nav = currentNavigators.length && currentNavigators.reduce((prevNav, nextNav) => {
			const yOffset = nextNav.current.getBoundingClientRect().y - 200;
			if (yOffset < 0) {
				if (prevNav) {
					const prevYOffset = prevNav.current.getBoundingClientRect().y - 200;
					if (prevYOffset >= yOffset) {
						return prevNav;
					}
				}
				return nextNav;
			}
			return null;
		}, null);

		const currentPathName = root.location.pathname.split('/');

		if (nav && nav.current) {
			this.setState({ activeHash: nav.current.id });
		} else {
			this.setState({ activeHash: currentPathName[1] });
		}
	}, 200);

	constructor(props) {
		super(props);
		this.state = {
			page: {},
			navigators: [],
			activeHash: '',
			isLoaded: false,
			pageNotFoundData: {},
			urlHistory: {
				source: document.referrer,
				url: window.location.href,
				referrer: null,
			},
			globalMetaTags: [],
		};
	}

	fetchPageData() {
		this.setState({
			page: {},
		});
		CoreService.fetchLandingPageData(root.location.pathname).then((data) => {
			this.setState({
				page: data,
				isLoaded: true,
			});
		});
	}

	fetchCoreData() {
		CoreService.fetchCoreData().then((data) => {
			this.setState({
				pageNotFoundData: data.pageNotFound,
			});
		});
	}

	setUrlHistory() {
		this.setState({
			urlHistory: {
				...this.state.urlHistory,
				source: document.referrer,
			},
		});
		if (this.state.urlHistory.url !== window.location.href) {
			this.setState({
				urlHistory: {
					...this.state.urlHistory,
					referrer: this.state.urlHistory.url,
				},
			});
		}
		this.setState({
			urlHistory: {
				...this.state.urlHistory,
				url: window.location.href,
			},
		});
	}

	fetchMetaTagData() {
		CoreService.fetchMetaTagsData().then((data) =>
			this.setState({
				globalMetaTags: data.metaTags,
			}),
		);
	}

	componentDidMount() {
		this.fetchCoreData();
		this.fetchPageData();
		root.addEventListener('scroll', this.setActiveHash);
		this.setUrlHistory();
		this.setActiveHash();
		this.fetchMetaTagData();
	}

	componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.fetchPageData();
		}
	}

	componentWillUnmount() {
		root.removeEventListener('scroll', this.setActiveHash);
	}

	render() {
		return (
			<CoreContext.Provider
				value={[
					this.state as CoreStoreData,
					(stateChange) => this.setState(stateChange),
				]}
			>
				{this.props.children}
			</CoreContext.Provider>
		);
	}
}

export const CoreProvider = withRouter(Provider);

const CoreContext = React.createContext<Store<CoreStoreData>>(undefined);

export default CoreContext;
