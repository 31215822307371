import { ContentItem } from 'kentico-cloud-delivery';
import get from 'lodash/get';
import KENTICO_TYPES from 'common/consts/kentico-types';

export default class ContentItemWithResolver extends ContentItem {
	constructor() {
		super({
			propertyResolver: (fieldName) => `_${fieldName}`,
		});
	}

	get type(): KENTICO_TYPES {
		return get(this, 'system.type', '');
	}

	get id() {
		return get(this, 'system.id', '');
	}
}
