import * as React from 'react';
import ThreeColumnsSectionModel from 'models/ThreeColumnsSectionModel';
import Layout from 'components/Layout/Layout';
import HorizontalLine from 'components/HorizontalLine/HorizontalLine';
import Button from 'components/Button/Button';
import ColumnParser from 'blocks/ThreeColumnsSection/ColumnParser';
import LinkParser from 'components/LinkParser/LinkParser';
import BlackNoiseBackground from 'components/BlackNoiseBackground/BlackNoiseBackground';
import KENTICO_TYPES from 'common/consts/kentico-types';
import TrackVisibility from 'react-on-screen';
import {
	useMediaMobile,
	useMediaTablet,
} from 'common/hooks/media';
import styles from './ThreeColumnsSection.module';

export interface ThreeColumnsSectionProps {
	data: ThreeColumnsSectionModel;
}

const ThreeColumnsSection: React.FC<ThreeColumnsSectionProps> = ({ data }) => {
	const {
		header,
		columns,
		link,
		mobileLink,
	} = data;

	const isTablet = useMediaTablet();
	const mediaMobile = useMediaMobile();

	const isWhiteBackground = columns.find(({ system }) => 
		system.type === KENTICO_TYPES.COLUMN_WITH_FULL_IMAGE);

	return (
		<section
			className={`${isWhiteBackground
				? styles.WhiteBackground 
				: styles.Background
			}`}
		>
			{!isWhiteBackground && <BlackNoiseBackground />}
			<Layout
				className={styles.Layout}
			>
				{mediaMobile ? (
					<TrackVisibility
						once={true}
					>
						{({ isVisible }) => isVisible ? (
							<h2 className={styles.Header}>
								{header}
							</h2>
						) : (
							<h2 className={styles.HeaderNotVisible}>
								{header}
							</h2>
						)}
					</TrackVisibility>
				) : (
					<h2 className={styles.Header}>
						{header}
					</h2>
				)}

				<div className={styles.Columns}>
					{columns.map((column) => (
						<ColumnParser
							className={styles.Column}
							key={column.id}
							data={column}
						/>
					))}
				</div>
				{link && (
					<React.Fragment>
						<HorizontalLine
							className={styles.HorizontalLine}
						/>
						<LinkParser
							data={mobileLink && !isTablet ? mobileLink : link}
							className={styles.ButtonContainer}
						>
							<Button
								iconClassName={styles.ButtonIcon}
								className={styles.Button}
								outline={true}
								icon={'assets/icons/click_icon.svg'}
							>
								{mobileLink && !isTablet ? mobileLink.text : link.text}
							</Button>
						</LinkParser>
					</React.Fragment>
				)}
			</Layout>
		</section>
	);
};

export default ThreeColumnsSection;
