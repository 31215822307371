import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';
import { Fields } from 'kentico-cloud-delivery';
import KENTICO_TYPES from 'common/consts/kentico-types';
import NavigatorModel from 'models/NavigatorModel';
import TaxonomyField = Fields.TaxonomyField;

export default class InnerLinkModel extends ContentItemWithResolver {
	static readonly type = KENTICO_TYPES.INNER_LINK;

	get text(): string {
		return get(this, '_text.value', '');
	}

	get slug(): TaxonomyField {
		return get(this, '_slug.taxonomyTerms[0].name', '');
	}

	get navigator(): NavigatorModel {
		return get(this, '_navigator[0]', null);
	}
}
