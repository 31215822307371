import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';
import KENTICO_TYPES from 'common/consts/kentico-types';
import { FieldModels } from 'kentico-cloud-delivery/_es2015';
import AssetModel = FieldModels.AssetModel;

export default class VideoSectionModel extends ContentItemWithResolver {
	static readonly type = KENTICO_TYPES.VIDEO_SECTION;

	get placeholderVideo(): AssetModel {
		return get(this, '_placeholder_video.assets[0]', null);
	}

	get mobilePlaceholderVideo(): AssetModel {
		return get(this, '_mobile_placeholder_video.assets[0]', null);
	}

    get videoUrl(): string {
        return get(this, '_video_url.value', '');
    }

    get mobileVideoUrl(): string {
        return get(this, '_video_url.value', '');
    }

	get buttonText(): string {
		return get(this, '_button_text.value', '');
	}
}
