import * as React from 'react';
import Media from 'react-media';
import MediaProps from 'components/Media/MediaProps';

const MediaTablet: React.FC<MediaProps> = ({ notMatches, children }) => {
	return (
		<Media query={{ minWidth: 768 }}>
			{(matches) => matches ? children : notMatches}
		</Media>
	);
};

MediaTablet.defaultProps = {
	notMatches: null,
};

export default MediaTablet;
