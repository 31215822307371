import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';
import KENTICO_TYPES from 'common/consts/kentico-types';
import { FieldModels } from 'kentico-cloud-delivery/_es2015';
import AssetModel = FieldModels.AssetModel;

export default class SliderSectionSlideModel extends ContentItemWithResolver {
	static readonly type = KENTICO_TYPES.SLIDER_SECTION_SLIDE;

	get blueHeadingText(): string {
		return get(this, '_blue_heading_text.value', '');
	}

	get headerTop(): string {
		return get(this, '_header_top.value', '');
	}

	get headerBottom(): string {
		return get(this, '_header_bottom.value', '');
	}

	get images(): AssetModel[] {
		return get(this, '_images.assets', []);
	}

	get mobileImages(): AssetModel[] {
		return get(this, '_mobile_images.assets', []);
	}

	get text(): string {
		return get(this, '_text.value', '');
	}
}
