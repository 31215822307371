import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';
import KENTICO_TYPES from 'common/consts/kentico-types';

export default class GlobalMetaTagModel extends ContentItemWithResolver {
	static readonly type = KENTICO_TYPES.GLOBAL_META_TAG;

	get name() {
		return get(this, '_name.value', '');
	}

	get content() {
		return get(this, '_content.value', '');
	}
}
