import * as React from 'react';
import ScrollSliderSectionModel from 'models/ScrollSliderSectionModel';
import root from 'window-or-global';
import ScrollSliderSectionSlide from 'blocks/ScrollSliderSection/ScrollSliderSectionSlide/ScrollSliderSectionSlide';
import Layout from 'components/Layout/Layout';
import Img from 'components/Img/Img';
import FullScreenSlider from 'blocks/ScrollSliderSection/FullScreenSlider/FullScreenSlider';
import {
	useMediaExtraLargeDesktop,
	useMediaTablet,
} from 'common/hooks/media';
import {
	useScrollTop,
} from 'common/hooks/common';
import {
	desktopNavHeight,
} from 'common/consts/responsive';
import styles from './ScrollSliderSection.module';

export interface ScrollSliderSectionProps {
	data: ScrollSliderSectionModel;
}

const ScrollSliderSection: React.FC<ScrollSliderSectionProps> = ({ data }) => {
	const {
		backgroundImage,
		slides,
	} = data;

	const bigImageHeightOffset = 720;
	const normalImageHeightOffset = 450;
	const horizontalScrollSpeed = 1.45;

	const [slideIndex, setSlideIndex] = React.useState(0);
	const [fullScreen, setFullScreen] = React.useState(false);

	const containerRef = React.useRef(null);
	const slidesContainerRef = React.useRef(null);
	const imageRef = React.useRef(null);

	const slidesRef = React.useRef(null);
	const scrollTop = useScrollTop();
	const isTablet = useMediaTablet();
	const isExtraLargeDesktop = useMediaExtraLargeDesktop();

	React.useEffect(() => {
		if (fullScreen) {
			const disableWheel = (e) => e.preventDefault();
			root.document.addEventListener('wheel', disableWheel, { passive: false });
			return () =>  root.document.removeEventListener('wheel', disableWheel);
		}
	}, [fullScreen]);

	React.useEffect(() => {
		if (containerRef && containerRef.current) {
			if (isExtraLargeDesktop) {
				containerRef.current.style.height = `${slides.length * bigImageHeightOffset}px`;
			} else if (isTablet) {
				containerRef.current.style.height = `${slides.length * normalImageHeightOffset}px`;
			} else {
				containerRef.current.style.height = 'auto';
			}
		}
	}, [containerRef, isTablet, isExtraLargeDesktop]);

	React.useEffect(() => {
		if (imageRef && imageRef.current
			&& slidesContainerRef && slidesContainerRef.current
			&& slidesRef && slidesRef.current) {

			const height = containerRef.current.getBoundingClientRect().height;

			if (isTablet && height > root.innerHeight) { // > tablet ? -> horizontal slider scroll:
				const topBounding = containerRef.current
					.getBoundingClientRect().top - desktopNavHeight;
				const bottomBounding = containerRef.current
					.getBoundingClientRect().bottom - root.innerHeight - desktopNavHeight;

				if (topBounding < 0 && bottomBounding > 0) { // is in viewport
					slidesContainerRef.current.style.top = `${desktopNavHeight}px`;
					slidesContainerRef.current.style.position = 'fixed';
					slidesRef.current.style.transform = `translateX(${topBounding * horizontalScrollSpeed}px)`;
					slidesContainerRef.current.style.pointerEvents = 'all';
				} else if (topBounding > 0 && bottomBounding > 0) { // is under viewport
					slidesContainerRef.current.style.top = `${0}px`;
					slidesContainerRef.current.style.position = 'relative';
					slidesRef.current.style.transform = `translateX(0px)`;
					slidesContainerRef.current.style.pointerEvents = 'all';
				} else { // is over viewport
					slidesRef.current.style.transform = `translateX(${topBounding * horizontalScrollSpeed}px)`;
					slidesContainerRef.current.style.pointerEvents = 'none';
				}
				imageRef.current.style.transform = `translateY(${topBounding / (35000 / height)}px)`; // image parallax
				// gallery opacity:
				slidesContainerRef.current.style.opacity = Math
					.max(0, Math.min((bottomBounding + root.innerHeight * 2 / 3) / 600, 1));
			} else {
				slidesContainerRef.current.style.opacity = 1;
				slidesContainerRef.current.style.top = '0px';
				slidesContainerRef.current.style.position = 'relative';
				slidesRef.current.style.transform = `translateX(0px)`;
			}
		}
	}, [scrollTop, slidesRef, imageRef, containerRef, slidesContainerRef, isTablet]);

	const onFullScreenClick = (index) => {
		setSlideIndex(index);
		setFullScreen(true);
	};

	return (
		<section
			ref={containerRef}
			className={styles.Container}
		>
			<div
				ref={slidesContainerRef}
				className={styles.Background}
			>
				<Img
					src={backgroundImage.url}
					alt={backgroundImage.description}
					className={styles.BackgroundImage}
					innerRef={imageRef}
				/>
				<Layout>
					{isTablet ? (
						<React.Fragment>
							<div
								ref={slidesRef}
								className={styles.Slides}
							>
								{slides.map((slideData, index) => (
									<ScrollSliderSectionSlide
										key={slideData.id}
										data={slideData}
										onFullScreen={() => onFullScreenClick(index)}
									/>
								))}
							</div>
						</React.Fragment>
					) : (
						<div
							ref={slidesRef}
							className={styles.MobileSlidesContainer}
						>
							{slides.map((slideData) => (
								<ScrollSliderSectionSlide
									key={slideData.id}
									data={slideData}
								/>
							))}
						</div>
					)}
				</Layout>
			</div>
			{fullScreen && (
				<FullScreenSlider
					slideIndex={slideIndex}
					setSlideIndex={setSlideIndex}
					slides={slides}
					setFullScreen={setFullScreen}
				/>
			)}
		</section>
	);
};

export default ScrollSliderSection;
