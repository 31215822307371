import * as React from 'react';
import { buildPhotoURL } from 'common/methods/kenticoResponsiveImage';
import {
	largeDesktopBreakpoint,
	mobileBreakpoint,
	regularDesktopBreakpoint,
	smallDesktopBreakpoint,
	tabletBreakpoint,
} from 'common/consts/responsive';
import styles from './Img.module';

const useResponsivePhoto = (maxWidth, src) => {
	const [img, setImg] = React.useState('');
	React.useEffect(() => {
		buildPhotoURL(maxWidth, src).then((responsiveImg) => {
			setImg(responsiveImg);
		});
	}, [img]);
	return img;
};

const Img: React.FC<any> = (props) => {
	const mobileImg = useResponsivePhoto(mobileBreakpoint, props.src);
	const tabletImg = useResponsivePhoto(tabletBreakpoint, props.src);
	const smallDekstopImg = useResponsivePhoto(smallDesktopBreakpoint, props.src);
	const regularDesktopImg = useResponsivePhoto(regularDesktopBreakpoint, props.src);
	const largeDesktopImg = useResponsivePhoto(largeDesktopBreakpoint, props.src);

	return (
		<img
			className={`${props.className ? props.className : ''} ${styles.Img}`}
			srcSet={`
								${mobileImg} ${mobileBreakpoint}w,
								${tabletImg} ${tabletBreakpoint}w,
								${smallDekstopImg} ${smallDesktopBreakpoint}w,
								${regularDesktopImg} ${regularDesktopBreakpoint}w,
								${largeDesktopImg} ${largeDesktopBreakpoint}w
							`}
			src={mobileImg}
			ref={props.innerRef}
			alt={props.alt}
			onClick={props.onClick}
		/>
	);
};

export default Img;
