import { DeliveryClient, TypeResolver } from 'kentico-cloud-delivery';
import * as models from 'models/index';

const typeResolvers = Object.values(models)
	.map((model) => new TypeResolver(model.type, () => new model()));

const previewApiKey = process.env.REACT_APP_KENTICO_PREVIEW_API_KEY || '';
const isPreview = previewApiKey !== '';

if (isPreview) {
	console.info('PREVIEW MODE IS ENABLED');
}

export default new DeliveryClient({
	projectId: process.env.REACT_APP_KENTICO_PROJECT_ID,
	enablePreviewMode: isPreview,
	basePreviewUrl: process.env.REACT_APP_PROXY_BASE_URL,
	baseUrl: process.env.REACT_APP_PROXY_BASE_URL,
	previewApiKey,
	typeResolvers,
});
