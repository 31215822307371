import KENTICO_TYPES from 'common/consts/kentico-types';
import KenticoClient from './kentico-client';
import { slashesToDashes } from 'common/methods/string';

export class CoreService {
	static async fetchCoreData() {
		const items = await KenticoClient
			.items()
			.type(KENTICO_TYPES.CORE)
			.depthParameter(10)
			.getPromise();

		if (items.isEmpty) {
			return null;
		}

		return items.lastItem;
	}

	static async fetchLandingPageData(slug) {
		const validSlug = slug && slug.endsWith('/') ? slug.slice(0, -1) : slug;
		const slugCodeName = validSlug ? `${slashesToDashes(validSlug)}` : '_';
		const response = await KenticoClient
			.items()
			.type(KENTICO_TYPES.PAGE)
			.containsFilter('elements.slug', [slugCodeName])
			.depthParameter(10)
			.getPromise();

		if (response.isEmpty) {
			return null;
		}

		return response.lastItem;
	}

	static async fetchMetaTagsData() {
		const items = await KenticoClient.items()
			.type(KENTICO_TYPES.GLOBAL_META_TAGS)
			.getPromise();

		if (items.isEmpty) {
			return null;
		}

		return items.lastItem;
	}
}
