import * as React from 'react';
import styles from './CheckBox.module';

export interface CheckBoxProps {
	valueHook: Array<string | any>;
	className?: string;
	required?: boolean;
	safeHtmlLabel: string;
}

const CheckBox: React.FC<CheckBoxProps> = (props) => {
	const {
		className,
		valueHook,
		required,
		safeHtmlLabel,
	} = props;

	const [value, setValue] = valueHook;

	return (
		<div
			className={`${styles.Container} ${className}`}
			onClick={() => setValue(!value)}
		>
			<div className={styles.CheckBox}>
					<span
						style={{ opacity: value ? 1 : 0 }}
						className={styles.CheckMark}
					/>
				<input
					className={styles.NativeCheckBox}
					type={'checkbox'}
					required={required}
					onChange={() => null}
					checked={value}
				/>
			</div>
			<label
				dangerouslySetInnerHTML={{ __html: safeHtmlLabel }}
				className={styles.Label}
			/>
		</div>
	);
};

CheckBox.defaultProps = {
	className: '',
	required: false,
};

export default CheckBox;
