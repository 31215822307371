import * as React from 'react';
import styles from './Dots.module.scss';

export interface DotsProps {
	count: number;
	white?: boolean;
	className?: string;
	onSelect?: (selectedIndex: number) => any;
	currentIndex?: number;
}

const Dots: React.FC<DotsProps> = (props) => {
	const {
		count,
		white,
		className,
		currentIndex,
		onSelect,
	} = props;

	return (
		<ul
			className={`${className} ${styles.Dots} ${white ? styles.White : ''}`}
		>
			{[...Array.from(Array(count).keys())].map((i) => (
				<li
					className={currentIndex === i ? styles.Active : ''}
					onClick={() => onSelect(i)}
					key={i}
				>
					<button className={styles.Button} />
				</li>
			))}
		</ul>
	);
};

Dots.defaultProps = {
	white: false,
	className: '',
	currentIndex: 0,
	onSelect: () => null,
};

export default Dots;
