import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';
import KENTICO_TYPES from 'common/consts/kentico-types';

export default class OuterLinkModel extends ContentItemWithResolver {
	static readonly type = KENTICO_TYPES.OUTER_LINK;

	get text(): string {
		return get(this, '_text.value', '');
	}

	get link(): string {
		return get(this, '_link.value', '');
	}
}
