import * as React from 'react';
import ContentItemWithColorAndPostitionModel 
  from 'models/ContentItemWithColorAndPostitionModel';
import { 
    COLOR,
    COLOR_VALUES, 
    POSITION,
    IMAGE_POSITION
  } from 'common/consts/common';
  import { useMediaTablet } from 'common/hooks/media';

export interface BackgroundProps {
  className?: string;
  data: Pick<ContentItemWithColorAndPostitionModel, 'textColor' | 'backgroundColor' | 'imagePosition'>;
  marginDimension?: string;
  children?: any;
}

export const setColor = (color: string) => {
  switch (color) {
  case COLOR.WHITE:
    return COLOR_VALUES[color];
  case COLOR.BLACK:
    return COLOR_VALUES[color];
  case COLOR.PRIMARY:
    return COLOR_VALUES[color];
  case COLOR.GREY:
    return COLOR_VALUES[color];
  default:
    return '';
  }
};

export const setImagePosition = (position: string) => {
    if(useMediaTablet()) {
       return IMAGE_POSITION[position];
    }
    return '';
}

const BackgroundAndImagePosition: React.FC<BackgroundProps> = ({
  className,
  data,
  children,
}) => {
  const {
    textColor,
    backgroundColor,
    imagePosition,
  } = data;

  return (
    <div
      className={className}
      style={{
        color: setColor(textColor),
        backgroundColor: setColor(backgroundColor),
        flexDirection: setImagePosition(imagePosition)
      }}
    >
      {children}
    </div>
  );
};

export default BackgroundAndImagePosition;
