import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import root from 'window-or-global';
import ContactFormModel from 'models/ContactFormModel';
import Input from 'components/Input/Input';
import Layout from 'components/Layout/Layout';
import Button from 'components/Button/Button';
import CheckBox from 'components/CheckBox/CheckBox';
import VoiceRecorder from 'components/VoiceRecorder/VoiceRecorder';
import {
	FORM_STATE,
	isFacebookApp,
	SUCCESS_MESSAGE_TIMEOUT,
} from 'common/consts/form';
import MailingService from 'services/mailing.service';
import TrackVisibility from 'react-on-screen';
import {
	useMediaMobile,
} from 'common/hooks/media';
import { removeTags } from 'common/methods/string';
import styles from './ContactForm.module';
import CoreContext from 'stores/CoreStore';

export interface ContactFormProps {
	data: ContactFormModel;
}

const ContactForm: React.FC<ContactFormProps> = ({ data }) => {
	const [formState, setFormState] = React.useState(FORM_STATE.DEFAULT);
	const [formChanged, setFormChanged] = React.useState(false);
	const [name, setName] = React.useState('');
	const [company, setCompany] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [phone, setPhone] = React.useState('');
	const [message, setMessage] = React.useState('');
	const [voiceMessage, setVoiceMessage] = React.useState(null);
	const [country, setCountry] = React.useState('PL');
	const [clientIP, setClientIP] = React.useState('');
	const [showCaptcha, setShowCaptcha] = React.useState(false);
	const [isCaptchaError, setIsCaptchaError] = React.useState(false);

	const [processingData, setProcessingData] = React.useState(false);
	const [{ urlHistory }] = React.useContext(CoreContext);
	const mediaMobile = useMediaMobile();

	React.useEffect(() => {
		fetch(process.env.REACT_APP_GEOLOCATION_API)
		  .then(res => res.json())  
		  .then(resData => {
			setCountry(resData.country)
			setClientIP(resData.ip)
		  })
	}, []);

	React.useEffect(() => {
		const checkCaptcha = async () => {
		const res = await root.fetch(process.env.REACT_APP_SPAM_BOT_PROTECTION, {
			method: 'POST',
			body: JSON.stringify({
				clientIP, 
				action: "check"
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		})
		if (res.status === 200) setShowCaptcha(false)
		if (res.status === 202) setShowCaptcha(true)
		}

		if(clientIP) checkCaptcha()
	}, [clientIP])

	const captchaRef = React.useRef(null);

	const handleCaptcha = () => {
		const token = captchaRef.current.getValue()
		if (token) {
			setIsCaptchaError(false)
		  	return false
		}
		setIsCaptchaError(true)
		return true
	}

	const processingDataSafeHtml = data.processingDataCheckBox
		? data.processingDataCheckBox.getHtml()
		: '';

	const resetForm = () => {
		setFormChanged(false);
		setName('');
		setCompany('');
		setEmail('');
		setPhone('');
		setMessage('');
		setVoiceMessage(null);
		setProcessingData(false);
	};

	const onSubmit = async (submitEvent) => {
		submitEvent.preventDefault();

		if (formState === FORM_STATE.ERROR) {
			setFormState(FORM_STATE.DEFAULT);
			return;
		}
		if (formState !== FORM_STATE.DEFAULT) {
			return;
		}

		setFormState(FORM_STATE.LOADING);

		if (data.useCaptcha) {
			if (showCaptcha) {
				const token = captchaRef.current.getValue();
				if (token) {
					try {
						const res = await root.fetch(process.env.REACT_APP_SPAM_BOT_PROTECTION, {
							method: 'POST',
							body: JSON.stringify({
								clientIP, 
								action: "submit",
								captchaUrl: `https://www.google.com/recaptcha/api/siteverify?secret=6Ld2rL0kAAAAAMu9_rMrS0HMhPpbzlO9L11OpDSS&response=${token}`
							}),
							headers: {
								'Content-Type': 'application/json',
							},
						})
						if (res.status === 200) setShowCaptcha(false)
						if (res.status === 202) setShowCaptcha(true)
					} catch {
						setIsCaptchaError(true)
						return
					}
				}
				setIsCaptchaError(true)
				return
			}
	
			const res = await root.fetch(process.env.REACT_APP_SPAM_BOT_PROTECTION, {
				method: 'POST',
				body: JSON.stringify({
					clientIP, 
					action: "submit",
				}),
				headers: {
					'Content-Type': 'application/json',
				},
			})
			if (res.status === 200) setShowCaptcha(false)
			if (res.status === 202) setShowCaptcha(true)
		}

		const checkReferrer = urlHistory.referrer === null ? 'no previous URL' : urlHistory.referrer;
		const res = MailingService.sendContactForm({
			name,
			company,
			email,
			phone,
			source: urlHistory.source,
			url: urlHistory.url,
			referrer: checkReferrer,
			message,
			voiceMessage,
			country
		});
		if (res) {
			setFormState(FORM_STATE.SUCCESS);
			setTimeout(() => {
				setFormState(FORM_STATE.DEFAULT);
			}, SUCCESS_MESSAGE_TIMEOUT);
			resetForm();
		} else {
			setFormState(FORM_STATE.ERROR);
		}
	};

	const buttonText = () => {
		switch (formState) {
			case FORM_STATE.ERROR:
				return data.sendErrorText;
			case FORM_STATE.LOADING:
				return data.sendLoadingText;
			case FORM_STATE.SUCCESS:
				return data.sendSuccessText;
			default:
				return data.sendButtonText;
		}
	};

	return (
		<Layout className={styles.Container}>
			<form
				onSubmit={onSubmit}
				onChange={() => setFormChanged(true)}
			>
				{mediaMobile ? (
					<TrackVisibility once={true}>
						{({ isVisible }) => isVisible ? (
							<React.Fragment>
								<h2 className={styles.Header}>
									{data.header}
								</h2>
								{data.underHeaderText && (
									<p className={styles.UnderHeaderText}>
										{data.underHeaderText}
									</p>
								)}
							</React.Fragment>
						) : (
							<React.Fragment>
								<h2 className={styles.HeaderNotVisible}>
									{data.header}
								</h2>
								{data.underHeaderText && (
									<p className={styles.UnderHeaderTextNotVisible}>
										{data.underHeaderText}
									</p>
								)}
							</React.Fragment>
						)}
					</TrackVisibility>
				) : (
					<React.Fragment>
						<h2 className={styles.Header}>
							{data.header}
						</h2>
						{data.underHeaderText && (
							<p className={styles.UnderHeaderText}>
								{data.underHeaderText}
							</p>
						)}
					</React.Fragment>
				)}

				<Input
					className={styles.Input}
					label={data.name}
					valueHook={[name, setName]}
					required={true}
				/>
				<Input
					className={styles.Input}
					label={data.company}
					valueHook={[company, setCompany]}
					required={true}
				/>
				<Input
					className={styles.Input}
					label={data.email}
					type={'email'}
					valueHook={[email, setEmail]}
					required={true}
				/>
				<Input
					className={styles.Input}
					label={data.phone}
					pattern={'^[0-9-+\s()]*$'}
					valueHook={[phone, setPhone]}
				/>
				<Input
					className={styles.Input}
					label={data.message}
					type={'textarea'}
					valueHook={[message, setMessage]}
					required={!voiceMessage}
				/>
				{!isFacebookApp() && (
					<div
						className={styles.VoiceRecorderContainer}
					>
						<VoiceRecorder
							valueHook={[voiceMessage, setVoiceMessage]}
							data={data.voiceRecorder}
						/>
					</div>
				)}
				{
					removeTags(processingDataSafeHtml) && (
						<div
							className={`${styles.CheckBoxes} ${formChanged ? styles.Visible : ''}`}
						>
							<CheckBox
								required={true}
								valueHook={[processingData, setProcessingData]}
								safeHtmlLabel={processingDataSafeHtml}
							/>
						</div>
					)
				}
				{data.useCaptcha && showCaptcha && (
					<div className={styles.CaptchaContainer}>
						<ReCAPTCHA 
							sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
							onChange={handleCaptcha}
							ref={captchaRef}
						/>
						{isCaptchaError && (
							<p className={styles.CaptchaError}>{data.captchaErrorMessage}</p>
						)}
					</div>
				)}
				<div
					className={styles.ButtonContainer}
				>
					<Button
						className={styles.Button}
						blue={true}
						type={'submit'}
					>
						{buttonText()}
					</Button>
				</div>
				{data.underButtonText && (
					<p
						className={styles.UnderButtonText}
					>
						{data.underButtonText}
					</p>
				)}
			</form>
		</Layout>
	);
};

export default ContactForm;
