import * as React from 'react';
import Img from 'components/Img/Img';
import styles from './Button.module';

export interface ButtonProps {
	icon?: string;
	iconElement?: any;
	type?: 'button' | 'submit' | 'reset';
	outline?: boolean;
	blue?: boolean;
	className?: string;
	iconClassName?: string;
	disabled?: boolean;
	onClick?: (clickEvent: any) => any;
}

const Button: React.FC<ButtonProps> = (
	{
		className,
		type,
		iconClassName,
		onClick,
		disabled,
		icon,
		iconElement,
		outline,
		blue,
		children,
	}) => (
	<button
		type={type}
		className={`
			${className}
			${styles.Button}
			${disabled ? styles.Disabled : ''}
			${outline ? styles.Outline : ''}
			${blue ? styles.Blue : ''}
		`}
		disabled={disabled}
		onClick={onClick}
	>
		<span className={styles.ButtonChildren}>
			{children}
		</span>
		{icon ? (
			<div className={styles.IconContainer}>
				<Img
					className={iconClassName}
					src={icon}
				/>
			</div>
		) : iconElement && (<div className={styles.IconContainer}>{iconElement}</div>)}
	</button>
);

Button.defaultProps = {
	className: '',
	iconClassName: '',
	iconElement: null,
	type: 'button',
	blue: false,
	outline: false,
	onClick: () => null,
};

export default Button;
