import * as React from 'react';
import styles from './HorizontalLine.module';

export interface HorizontalLineProps {
	className?: string;
}

const HorizontalLine: React.FC<HorizontalLineProps> = ({ className }) => (
	<hr
		className={`${className} ${styles.HorizontalLine}`}
	/>
);

export default HorizontalLine;
