import * as React from 'react';
import CoreContext from 'stores/CoreStore';
import { PageContentParser } from 'containers/PageContentParser';
import PageNotFound from 'containers/PageNotFound/PageNotFound';
import Navigation from 'blocks/Navigation/Navigation';
import Footer from 'blocks/Footer/Footer';
import SEO from 'blocks/SEO/SEO';

const Page: React.FC<any> = () => {
	const [data, setCoreState] = React.useContext(CoreContext);
	const {
		page,
		activeHash,
		smoothScroll,
		navigators,
		scrollToSection,
		isLoaded,
		globalMetaTags,
	} = data;

	const currentNavigators = navigators.filter((navigator) => navigator.current);

	React.useEffect(() => {
		if (currentNavigators.length) {
			const currentNavigator = currentNavigators.find((navigator) => navigator.current.id === scrollToSection);
			if (currentNavigator) {
				currentNavigator.current.scrollIntoView({ behavior: (smoothScroll ? 'smooth' : 'auto'), block: 'start' });
				setCoreState({ scrollToSection: '' });
			}
		}
	}, [currentNavigators.length, activeHash]);

	if (!page) {
		if (!isLoaded) {
			return null;
		}
		return (
			<PageNotFound />
		);
	}

	return (
		<React.Fragment>
			{(page.SEO || globalMetaTags) && <SEO data={page.SEO} globalMetaTags={globalMetaTags} />}
			{page.navigation && <Navigation data={page.navigation} />}
			<PageContentParser
				content={page.content}
			/>
			{page.footer && <Footer data={page.footer} />}
		</React.Fragment>
	);
};

export default Page;
