import * as React from 'react';
import SmallColumnsSectionModel from 'models/SmallColumnsSectionModel';
import Layout from 'components/Layout/Layout';
import SmallColumn from 'blocks/SmallColumnsSection/SmallColumn/SmallColumn';
import {
	useMediaMobile,
} from 'common/hooks/media';
import TrackVisibility from 'react-on-screen';
import styles from './SmallColumnsSection.module';

export interface SmallColumnsSectionProps {
	data: SmallColumnsSectionModel;
}

const rowGroup = (list, rows) => list.reduce((prev, column) => {
	if (prev[prev.length - 1].length < rows) {
		prev[prev.length - 1].push(column);
		return prev;
	} else {
		return [...prev, [column]];
	}
}, [[]]);

const SmallColumnsSection: React.FC<SmallColumnsSectionProps> = ({ data }) => {
	const {
		header,
		text,
		smallColumns,
	} = data;

	const mediaMobile = useMediaMobile();

	return (
		<section
			className={styles.Background}
		>
			<Layout>
				<header className={styles.HeaderContainer}>
					<TrackVisibility
						once={true}
						offset={200}
					>
						{({ isVisible }) => isVisible ? (
							<React.Fragment>
								<h2 className={styles.Header}>
									{header}
								</h2>
								<p className={styles.Text}>
									{text}
								</p>
							</React.Fragment>
							) : (
							<React.Fragment>
								<h2 className={styles.HeaderNotVisible}>
									{header}
								</h2>
								<p className={styles.TextNotVisible}>
									{text}
								</p>
							</React.Fragment>
						)}
					</TrackVisibility>
				</header>
				<div className={styles.Rows}>
					{rowGroup(smallColumns, mediaMobile ? 3 : 3)
						.map((row, index) => (
							<div
								className={styles.Row}
								key={index}
							>
								{row.map((column) => (
									<SmallColumn
										className={styles.Column}
										key={column.id}
										data={column}
									/>
								))}
							</div>
					))}
				</div>
			</Layout>
		</section>
	);
};

export default SmallColumnsSection;
