// MEDIA:
export const mobileBreakpoint = 500;
export const tabletBreakpoint = 768;
export const smallDesktopBreakpoint = 1100;
export const regularDesktopBreakpoint = 1366;
export const largeDesktopBreakpoint = 1920;
export const extraLargeDesktopBreakpoint = 2560;

// NAV:
export const desktopNavHeight = 90;
export const mobileNavHeight = 50;
