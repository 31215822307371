import * as React from 'react';
import styles from './Layout.module';

export interface LayoutProps {
	className?: string;
}

const Layout: React.FC<LayoutProps> = ({ className, children }) => (
	<div
		className={`${className} ${styles.Layout}`}
	>
		{children}
	</div>
);

Layout.defaultProps = {
	className: '',
};

export default Layout;
