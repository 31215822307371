import * as React from 'react';
import { Helmet } from 'react-helmet';
import Navigation from 'blocks/Navigation/Navigation';
import Footer from 'blocks/Footer/Footer';
import SEO from 'containers/Page';
import CoreContext from 'stores/CoreStore';
import styles from './PageNotFound.module.scss';

const PageNotFound = () => {
	const [{ pageNotFoundData }] = React.useContext(CoreContext);

	return (
		<div className={styles.Wrapper}>
			<Helmet>
				<meta name='prerender-status-code' content='404'/>
			</Helmet>
			{pageNotFoundData.SEO && <SEO data={pageNotFoundData.SEO}/>}
			{pageNotFoundData.navigation && <Navigation data={pageNotFoundData.navigation}/>}
			<div className={styles.PageNotFoundContainer}>
				<h2 className={styles.ErrorCode}> 404 </h2>
				<span className={styles.PageNotFoundText}>
					We are sorry, the page you requested cannot be found.
				</span>
			</div>
			{pageNotFoundData.footer && <Footer data={pageNotFoundData.footer}/>}
		</div>
	);
};

export default PageNotFound;
