import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';
import KENTICO_TYPES from 'common/consts/kentico-types';
import { FieldModels } from 'kentico-cloud-delivery/_es2015';
import AssetModel = FieldModels.AssetModel;

export default class PartnersSectionModel extends ContentItemWithResolver {
	static readonly type = KENTICO_TYPES.PARTNERS_SECTION;

	get partners(): AssetModel[] {
		return get(this, '_partners.assets', []);
	}
}
