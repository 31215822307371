import * as React from 'react';
import PartnersSectionModel from 'models/PartnersSectionModel';
import Layout from 'components/Layout/Layout';
import Img from 'components/Img/Img';
import Slider from 'react-slick';
import {
	useMediaTablet,
} from 'common/hooks/media';
import styles from './PartnersSection.module';

export interface PartnersSectionProps {
	data: PartnersSectionModel;
}

const PartnersSection: React.FC<PartnersSectionProps> = ({ data }) => {
	const mediaTablet = useMediaTablet();

	return (
		<section className={styles.Background}>
			<Layout>
				<div className={styles.ImagesContainer}>
					<Slider
						className={styles.Slider}
						slidesToShow={mediaTablet ? 3 : 2}
						autoplaySpeed={1500}
						infinite={true}
						autoplay={true}
					>
						{data.partners.map((partner) => (
							<div
								key={partner.url}
								className={styles.PartnerImageContainer}
							>
								<Img
									className={styles.Image}
									src={partner.url}
									alt={partner.description}
								/>
							</div>
						))}
					</Slider>
				</div>
			</Layout>
		</section>
	);
};

export default PartnersSection;
