import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';
import KENTICO_TYPES from 'common/consts/kentico-types';
import GlobalMetaTagModel from './GlobalMetaTagModel';

export default class GlobalMetaTagsModel extends ContentItemWithResolver {
	static readonly type = KENTICO_TYPES.GLOBAL_META_TAGS;

	get metaTags(): GlobalMetaTagModel {
		return get(this, '_tags', []);
	}
}
