/* global FileReader */

const convertBlobToBase64 = (blob) => new Promise((resolve, reject) => {
	if (!blob) {
		return;
	}
	const reader = new FileReader();
	reader.onerror = reject;
	reader.onload = () => {
		resolve((reader.result as string).split(',')[1]);
	};
	reader.readAsDataURL(blob);
});

export default convertBlobToBase64;
