import * as React from 'react';
import ColumnWithFullScreenImageModel  from 'models/ColumnWithFullScreenImageModel';
import Asset from 'components/Asset/Asset';
import Img from 'components/Img/Img';
import FullScreenColumn from './FullScreenColumn/FullScreenColumn';	
import styles from './ColumnWithFullScreenImage.module';

export interface ColumnWithFullScreenImageProps {
	data: ColumnWithFullScreenImageModel;
	className?: string;
}

const ColumnWithFullScreenImage: React.FC<ColumnWithFullScreenImageProps> = ({ data, className  }) => {

	const [fullScreen, setFullScreen] = React.useState(false);
	const {
		headerTop,
        headerBottom,
        text,
        smallText,
		image,
	} = data;

	return (
		<div className={`${styles.Container} ${className}`}>
			<div
				className={styles.AssetContainer}
			>
				<div
					className={styles.FullScreenIconContainer}
					onClick={() => setFullScreen(true)}
				>
					<Img
						className={styles.FullScreenIcon}
						src={'/assets/icons/fullscreen_icon.svg'}
						alt={'fullscreen'}
					/>
				</div>
				<Asset
					asset={image}
					className={styles.Asset}
				/>
				<div className={styles.Notched} />
			</div>
			<header className={styles.HeaderContainer}>
				<h3 className={styles.HeaderTop}>
					{headerTop}
				</h3>
				<h3 className={styles.headerBottom}>
					{headerBottom}
				</h3>
			</header>
			<p
				className={styles.Text}
			>
				{text}
			</p>
            <p
				className={styles.SmallText}
				dangerouslySetInnerHTML={{ __html: smallText }}
            />
			{fullScreen && (
				<FullScreenColumn
					asset={image}
					setFullScreen={() => setFullScreen(false)}
				/>
			)}
		</div>
	);
};

ColumnWithFullScreenImage.defaultProps = {
	className: '',
};

export default ColumnWithFullScreenImage;
