import * as React from 'react';
import ContentItemWithResolver from 'models/ContentItemWithResolver';
import KENTICO_TYPES from 'common/consts/kentico-types';
import Column from './Column/Column';
import ColumnWithLink from './ColumnWithLink/ColumnWithLink';
import ColumnModel from 'models/ColumnModel';
import ColumnWithLinkModel from 'models/ColumnWithLinkModel';
import ColumnWithFullScreenImage from './ColumnWithFullScreenImage/ColumnWithFullScreenImage';
import ColumnWithFullScreenImageModel from 'models/ColumnWithFullScreenImageModel';

export interface ColumnParserProps {
	data: ContentItemWithResolver;
	className?: string;
}

const ColumnParser: React.FC<ColumnParserProps> = ({ data, className }) => {
	switch (data.type) {
		case KENTICO_TYPES.COLUMN:
			return (
				<Column
					className={className}
					data={data as ColumnModel}
				/>
			);
		case KENTICO_TYPES.COLUMN_WITH_LINK:
			return (
				<ColumnWithLink
					className={className}
					data={data as ColumnWithLinkModel}
				/>
			);
		case KENTICO_TYPES.COLUMN_WITH_FULL_IMAGE:
			return (
				<ColumnWithFullScreenImage 
					className={className}
					data={data as ColumnWithFullScreenImageModel}
				/>
			)
	}
	return null;
};
ColumnParser.defaultProps = {
	className: '',
};
export default ColumnParser;
