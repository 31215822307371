import * as React from 'react';
import root from 'window-or-global';

export const useDidMount = () => {
	const [didMount, setDidMount] = React.useState(false);
	React.useEffect(() => {
		setDidMount(true);
		return () => {
			setDidMount(false);
		};
	}, [didMount]);
};

export const useScrollTop = () => {
	const [scrollTop, setScrollTop] = React.useState(0);
	const didMount = useDidMount();
	React.useEffect(() => {
		const scrollToTop = () => {
			setScrollTop(root.pageYOffset);
		};
		root.addEventListener('scroll', scrollToTop);
		return () => {
			root.removeEventListener('scroll', scrollToTop);
		};
	}, [didMount]);
	return scrollTop;
};
