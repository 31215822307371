import * as React from 'react';
import NavigatorModel from 'models/NavigatorModel';
import CoreContext from 'stores/CoreStore';
import styles from './Navigator.module';

export interface NavigatorProps {
	data: NavigatorModel;
}

const Navigator: React.FC<NavigatorProps> = ({ data }) => {
	const navigatorRef = React.useRef(null);
	const [{ navigators }, setCoreState] = React.useContext(CoreContext);

	React.useEffect(() => {
		if (navigatorRef
			&& navigatorRef.current
			&& !navigators.some((navigator) => navigator === navigatorRef)) {
			setCoreState({ navigators: [...navigators, navigatorRef] });
		}
	}, [navigatorRef, navigators]);

	return (
		<div
			className={styles.Navigator}
			id={data.hashText}
			ref={navigatorRef}
		/>
	);
};

export default Navigator;
