import * as React from 'react';
import { withRouter } from 'react-router';
import { History } from 'history';
import { Fields } from 'kentico-cloud-delivery';
import TaxonomyField = Fields.TaxonomyField;

import InnerLinkModel from 'models/InnerLinkModel';
import CoreContext from 'stores/CoreStore';
import styles from './InnerLink.module';

export interface InnerLinkProps {
	data: InnerLinkModel;
	className?: string;
	history: History;
	match: {
		url: TaxonomyField;
	};
}

const InnerLink: React.FC<InnerLinkProps> = ({ className, data, children, history, match }) => {
	const [state, setCoreState] = React.useContext(CoreContext);

	if (!data) {
		return null;
	}

	const onClick = () => {
		const {
			slug, navigator,
		} = data;

		const smoothScroll = slug ? slug === match.url : true;
		let activeHash;
		let scrollToSection;

		if (slug) {
			history.push(slug);
			activeHash = '';
			scrollToSection = '';
		}

		if (navigator) {
			activeHash = navigator.hashText;
			scrollToSection = navigator.hashText;
		}

		setCoreState({
			activeHash,
			scrollToSection,
			smoothScroll,
		});
	};

	return (
		<a
			className={`${className} ${styles.AnchorLink}`}
			onClick={onClick}
		>
			{children || data.text}
		</a>
	);
};

InnerLink.defaultProps = {
	className: '',
};

export default withRouter(InnerLink);
