import * as React from 'react';

export interface VideoProps {
	className?: string;
	src: string;
	title?: string;
	muted?: boolean;
	autoPlay?: boolean;
	loop?: boolean;
	playsInline?: boolean;
	innerRef?: any;
	onPause?: () => any;
}

const Video: React.FC<VideoProps> = (props) => {
	return (
		<video
			ref={props.innerRef}
			className={props.className}
			src={props.src}
			title={props.title}
			muted={props.muted}
			autoPlay={props.autoPlay}
			loop={props.loop}
			playsInline={props.playsInline}
			onPause={props.onPause}
		/>
	);
};

Video.defaultProps = {
	className: '',
	src: '',
	title: '',
	muted: true,
	autoPlay: true,
	loop: true,
	playsInline: true,
	onPause: null,
	innerRef: null,
};

export default Video;
