import * as React from 'react';
import {
	FieldModels,
} from 'kentico-cloud-delivery';
import AssetModel = FieldModels.AssetModel;
import Img from 'components/Img/Img';
import Video from 'components/Video/Video';

export interface AssetProps {
	asset: AssetModel;
	className?: string;
	videoProps?: any;
	imageProps?: any;
	innerRef?: any;
	onClick?: () => any;
}

const Asset: React.FC<AssetProps> = (props) => {
	const {
		asset,
		className,
		innerRef,
		videoProps,
		imageProps,
		onClick,
	} = props;

	if (!asset) {
		return null;
	}
	return asset.type === 'video/mp4'
		? (
			<Video
				className={className}
				src={asset.url}
				title={asset.description}
				innerRef={innerRef}
				onClick={onClick}
				{...videoProps}
			/>
		) : (
			<Img
				className={className}
				src={asset.url}
				alt={asset.description}
				innerRef={innerRef}
				onClick={onClick}
				{...imageProps}
			/>
		);
};

Asset.defaultProps = {
	className: '',
	onClick: null,
};

export default Asset;
